import { action, computed, makeObservable, observable } from "mobx"
import config from "./store.config"
import _, { sortBy } from "lodash-es"
import FuzzySearch from "fuzzy-search"
import moment from "moment"
import queryString from "query-string"
import labourServiceStore from "page/LabourTimeServicePage/labourServiceStore"
import defaultStore from "data/store.json"
import { VehicleModel } from "./VehicleModel"
import VehicleMeta from "model/VehicleMeta"
import * as Sentry from "@sentry/browser"
import { VehicleProfile } from "./VehicleProfile"
import StoreModel from "../model/StoreModel"
import { toast } from "react-toastify"
import store from "store"
// const store = new Map()
window.store = store

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 25000 } = options
  if (!AbortController) {
    return await fetch(resource, {
      ...options
    })
  }

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  })
  clearTimeout(id)

  return response
}

export default class LocationStore {

  stores = [defaultStore]
  storeById = new Map()
  storesByRegion = new Map()
  storesByBrand = new Map()
  regions = []
  activeStoreId = "NACARMOS"
  activeManufacturerKey = null
  activeManufacturer = null
  activeVehicleReferenceId = ""
  activeVehicleClass = ""
  activeVehicleMeta = false
  activeVehicleClassId = "CAR"
  activeVehicleClassIdRequestKey = "CAR" //e.g. VAN uses "CAR" to request services
  activeVIN = ""
  activeVehicle = null
  activeVehicleProfile = false
  activeVehicleProfileId = false
  activeQuality = "Recommended"
  activeCategory = false
  activeCategoryId = false
  activeOrderTypes = []
  ready = false
  busy = false
  isError = false
  debugMode = false
  hasGeoData = localStorage.getItem("hasGeoDataOk")

  isTeam = false
  isOnlineStore = false
  onlineStore = false // ? Duplicate? => badly named => NACARMOS store obj later

  primaryBrands = []
  primaryBrandByKey = {}

  // categoryId the user initially requested
  intent = null

  isDesktop = null
  isHuge = null
  isTablet = null
  isLaptop = null
  isMobile = null
  isPortrait = null
  isRetina = null

  loading = true
  loadingProgress = false
  loadingDuration = false

  isInternalDomain = false

  lat = false
  lng = false

  searcher = false
  vehicleClasses = {
    BIKE: {
      sort: 0,
      flip: true,
      features: {
        NO_VEHICLE: true
      },
      brandSuggestion: "TRIUMPH MOTORRÄDER",
      id: "BIKE",
      name: "Bike",
      image: "/shop/picto-vehicle-classes/neu/bike.jpg",
      image_xl: "/shop/picto-vehicle-classes/neu/bike_xl.png"
    },

    CAR: {
      sort: 1,
      id: "CAR",
      name: "PKW",
      image: "/shop/picto-vehicle-classes/neu/car.jpg",
      image_xl: "/shop/picto-vehicle-classes/neu/car.jpg_xl.png",
      pickStrategy: "PICK_VEHICLE"
    }, // SUV          : {
    //     id        : "SUV",
    //     requestKey: "CAR",
    //     name      : "SUV/Pick-up",
    //     image     : "/shop/picto-vehicle-classes/neu/suv.jpg",
    //     pickStrategy : "PICK_VEHICLE"
    // },
    VAN: {
      sort: 2,
      id: "VAN",
      requestKey: "CAR",
      name: "VAN",

      image: "/shop/picto-vehicle-classes/neu/van.jpg",
      image_xl: "/shop/picto-vehicle-classes/neu/van_xl.png",
      pickStrategy: "PICK_VEHICLE"
    },
    TRUCK: {
      sort: 3,
      id: "TRUCK",
      flip: false,
      name: "LKW",
      features: {
        NO_VEHICLE: true
      },
      pickStrategy: "PICK_VEHICLE",
      image: "/shop/picto-vehicle-classes/neu/truck.jpg",
      image_xl: "/shop/picto-vehicle-classes/neu/truck_xl.png"
    },
    SMALLTRAILERS: {
      sort: 4,
      flip: true,
      features: {
        NO_BRAND: false,
        NO_VEHICLE: true
      },
      id: "SMALLTRAILERS",
      requestKey: "SMALLTRAILERS",
      name: "Anhänger",
      brandSuggestion: "NACARMOS",
      image: "/shop/picto-vehicle-classes/neu/trailer_small.jpg",
      image_xl: "/shop/picto-vehicle-classes/neu/trailer_small_xl.png"
    },

    TRAILER_HORSE: {
      sort: 5,
      id: "TRAILER_HORSE",
      flip: false,
      features: {
        NO_BRAND: false,
        NO_VEHICLE: true
      },
      requestKey: "SMALLTRAILERS",
      name: "Pferdeanhänger",
      brandSuggestion: "NACARMOS",
      image: "/shop/picto-vehicle-classes/neu/trailer_horse.jpg",
      image_xl: "/shop/picto-vehicle-classes/neu/trailer_horse.jpg"
    },
    CARAVAN: {
      sort: 6,
      flip: true,
      id: "CARAVAN",
      features: {
        NO_BRAND: true,
        NO_VEHICLE: true
      },
      requestKey: "SMALLTRAILERS",
      name: "Wohnwagen",
      brandSuggestion: "NACARMOS",
      image: "/shop/picto-vehicle-classes/neu/caravan.jpg",
      image_xl: "/shop/picto-vehicle-classes/neu/caravan_xl.png"
    },
    TRAILER: {
      sort: 7,
      id: "TRAILER",
      features: {
        NO_BRAND: true,
        NO_VEHICLE: true
      },
      name: "LKW-Anhänger",
      brandSuggestion: "NACARMOS",
      image: "/shop/picto-vehicle-classes/neu/trailer.jpg",
      image_xl: "/shop/picto-vehicle-classes/neu/trailer_xl.png"
    },
    COOLING: {
      sort: 8,
      id: "COOLING",
      features: {
        NO_BRAND: true,
        NO_VEHICLE: true
      },
      name: "Kühlung",
      brandSuggestion: "NACARMOS",
      image: "/shop/picto-vehicle-classes/neu/cooling.jpg",
      image_xl: "/shop/picto-vehicle-classes/neu/cooling_xl.png"
    }
  }
  repairLookup = {
    "CAR": "PKW",
    "BIKE": "Bike",
    "TRUCK": "Truck",
    "VAN": "Van"
  }

  constructor() {
    makeObservable(this, {
      stores: observable,
      busy: observable,
      storeById: observable,
      storesByRegion: observable,
      storesByBrand: observable,
      regions: observable,
      activeStoreId: observable,
      activeManufacturerKey: observable,
      activeManufacturer: observable,
      activeVehicleReferenceId: observable,
      activeVehicleClass: observable,
      activeVehicleMeta: observable,
      activeVehicleClassId: observable,
      activeVehicleClassIdRequestKey: observable,
      activeVIN: observable,
      activeVehicle: observable,
      activeVehicleProfile: observable,
      activeVehicleProfileId: observable,
      activeQuality: observable,
      activeCategory: observable,
      activeCategoryId: observable,
      activeOrderTypes: observable,
      ready: observable,
      isError: observable,
      debugMode: observable,
      hasGeoData: observable,
      isTeam: observable,
      isOnlineStore: observable,
      onlineStore: observable,
      isDesktop: observable,
      isHuge: observable,
      isTablet: observable,
      isLaptop: observable,
      isMobile: observable,
      isPortrait: observable,
      isRetina: observable,
      loading: observable,
      loadingProgress: observable,
      loadingDuration: observable,
      isInternalDomain: observable,
      setDebugMode: action,
      setBusy: action,
      setLoading: action,
      setMediaQuery: action,
      setIntent: action,
      reset: action,
      activeManufacturerDisplayName: computed,
      setActiveCategory: action,
      requestGeoData: action,
      setDefaultStoreFromURL: action,
      setActiveVIN: action,
      hydrate: action,
      setDomainTypeInternal: action,
      clearVehicleProfile: action,
      setActiveVehicleReferenceId: action,
      setActiveVehicleClassId: action,
      setActiveVehicleProfileId: action,
      setActiveVehicleProfile: action,
      setActiveQuality: action,
      setActiveVehicle: action,
      setActiveVehicleMeta: action,
      setActiveStoreId: action,
      setActiveManufacturerKey: action,
      baseUrl: computed,
      activeStore: computed,
      setActiveVehicleSeries: action,
      sortByDistance: action,
      queryString: computed,
      get: action
    })

    let storeId = this.getItem("ls:activeStore") || "NACARMOS"
    this.activeStoreId = storeId
  }

  get activeManufacturerDisplayName() {
    return this.activeManufacturer?.name
  }

  get forceBrandSelection() {
    return this.activeManufacturerKey === null
  }

  get baseUrl() {
    return this.createBaseUrl()
  }

  get activeStore() {
    if (this.activeStoreId) return this.storeById.get(this.activeStoreId || "NACARMOS")
    return false
  }

  get queryString() {
    let qs = queryString.stringify({
      store: this.activeStoreId,
      manufacturerKey: this.activeManufacturerKey
    })
    return qs
  }

  reset() {
    console.log("RESET")
    this.setActiveVehicleProfile(false)
    this.setActiveVehicleProfileId(false)
    this.setActiveStoreId("NACARMOS")
    this.setActiveVehicle(false, false)
  }

  toQuery(additionalParams = {}) {
    const vehicleProfile = locationStore.activeVehicleProfile
    const query = queryString.parse(window.location.search.slice(1))
    query.store = locationStore.activeStoreId
    query.vehicleReferenceId = locationStore.activeVehicleReferenceId
    query.manufacturerKey = locationStore.activeManufacturerKey
    if (vehicleProfile) {
      const {
        configurationId,
        mileage,
        firstRegistration
      } = vehicleProfile
      query.configurationId = configurationId
      query.mileage = mileage
      query.gearbox = vehicleProfile.gearboxId
      query.firstRegistration = firstRegistration
      console.log({
        vehicleProfile,
        configurationId,
        mileage,
        firstRegistration
      })
    }
    return queryString.stringify({ ...query, ...additionalParams })
  }

  setDebugMode(mode) {
    this.debugMode = !!mode
  }

  setLoading(isLoading, duration = 1200) {
    this.loading = isLoading
    this.loadingDuration = duration
    if (this.timeoutID) clearTimeout(this.timeoutID)

    this.timeoutID = window.setTimeout(() => {
      this.loading = false
    }, duration)
  }

  setIntent(categoryId) {
    this.intent = categoryId
  }

  setBusy(isBusy) {
    this.busy = isBusy
  }

  setMediaQuery({
    isDesktop,
    isHuge,
    isLaptop,
    isPortrait,
    isRetina,
    isTablet,
    isMobile
  }) {
    this.isDesktop = isDesktop
    this.isHuge = isHuge
    this.isTablet = isTablet
    this.isMobile = isMobile
    this.isPortrait = isPortrait
    this.isLaptop = isLaptop
    this.isRetina = isRetina
  }

  setActiveCategory(category) {
    this.activeCategory = category
    this.activeCategoryId = category && category.id
  }

  guessStoreForBrand(manufacturer) {
    let guess = this.stores.filter(store => store.brands.includes(manufacturer))
    return guess || this.storeById.get("NACARMOS")
  }

  async requestGeoData() {
    console.log("REQUEST GEO DATA")
    let p = new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition((d) => {
          console.log("RECEIVED GEO DATA", d)
          if (d.coords) {
            this.sortByDistance({
              lat: d.coords.latitude,
              lng: d.coords.longitude
            })
          }
          localStorage.setItem("hasGeoDataOk", true)
          this.hasGeoData = true
          localStorage.setItem("hasGeoDataOk", true)
          return resolve(false)
        })
      } catch (err) {
        console.error(err, "Geolocation unavailable")
        this.hasGeoData = true
        resolve(false)
      }
    }, console.error, { timeout: 1000 })
    return p
  }

  setDefaultStoreFromURL() {
    const query = queryString.parse(document.location.search)

    if (query.store) {
      this.setActiveStoreId(query.store)
    }
    if (query.brand || query.manufacturerKey) {
      this.setActiveManufacturerKey(query.brand || query.manufacturerKey)
    }

    if (query.vehicleClass) {
      this.setActiveVehicleClassId(query.vehicleClass)
    }
  }

  setActiveVIN(activeVIN) {
    this.activeVIN = activeVIN
  }

  async hydrate() {
    this.setDefaultStoreFromURL()
    this.activeQuality = this.getItem("ls:activeQuality") || "Recommended"
    let activeVehicleClassId = this.getItem("ls:vehicleClass") || false
    if (activeVehicleClassId) {
      this.setActiveVehicleClassId(activeVehicleClassId)
    }
    let storeId = this.getItem("ls:activeStore") || "NACARMOS"
    let vehicleReferenceId = this.getItem("ls:activeVehicleReferenceId")

    if (vehicleReferenceId === "false") vehicleReferenceId = ""
    if (vehicleReferenceId) {
      this.setActiveVehicleReferenceId(vehicleReferenceId)
      let vehicle = await criteriaStore.getCar(vehicleReferenceId)
      this.setActiveVehicle(vehicle)
    }

    let vehicleProfileId = this.getItem("ls:vehicleProfileId")
    if (vehicleProfileId) {
      this.setActiveVehicleProfileId(vehicleProfileId)
      let vehicleProfile = sessionStore.vehicleById.get(vehicleProfileId)
      if (vehicleProfile) {
        this.setActiveVehicleProfile(vehicleProfile)
      }
      else {
        // GET LOCAL
        let data = this.getItem(vehicleProfileId)
        if (data) {
          vehicleProfile = new VehicleProfile(data)
          this.setActiveVehicleProfile(vehicleProfile)
        }
      }
    }
    else {
      // LOAD NO-SESSION PROFILE
      let vehicleProfileData = this.getItem("ls:vehicleProfile")
      if (vehicleProfileData) {
        this.setActiveVehicleProfile(new VehicleProfile(vehicleProfileData))
        console.log("LOAD VEHICLEPROFILE FROM LS", this.activeVehicleProfile)
      }
    }

    if (!this.activeVehicleProfile) {
      this.setActiveVehicleProfile(new VehicleProfile({}))
    }

    console.log("hydrate", {
      vehicleReferenceId,
      vehicleProfileId
    })
    let manufacturerKey = this.getItem("ls:activeManufacturerKey")
    if (manufacturerKey === "false") manufacturerKey = ""
    if (manufacturerKey) this.setActiveManufacturerKey(manufacturerKey)
    this.setActiveStoreId(storeId)
    serviceStore.fetchVehicleTypes({})
    if (!this.activeVehicleReferenceId) await labourServiceStore.fetchClusterList({})

  }

  setItem(key, value, retryCount = 0) {
    try {
      store.set(key, value)
    } catch (error) {
      console.error("Error setting item:", error)
      if (retryCount < 5) {
        this.evictOptionals()
        this.setItem(key, value, retryCount + 1)
      }
    }
  }

  getItem(key) {
    return store.get(key)
  }

  evictOptionals() {
    Object.keys(localStorage)
      .forEach(key => {
        if (key.startsWith("local:")) {
          store.remove(key)
        }
        if (key.startsWith("http")) {
          store.remove(key)
        }
      })
  }

  removeItem(key) {
    return store.remove(key)
  }

  setDomainTypeInternal(isInternal = false) {
    this.isInternalDomain = isInternal
    this.isTeam = isInternal
  }

  search(item) {
    return this.searcher ? this.searcher.search(item) : []
  }

  clearVehicleProfile() {
    this.activeVehicle = false
    this.activeVehicleReferenceId = false
    this.activeVehicleProfile = false
    this.activeVehicleProfileId = false
  }

  async setActiveVehicleReferenceId(vehicleReferenceId) {
    Sentry.setContext("vehicle", { vehicleReferenceId })

    this.activeVehicleReferenceId = vehicleReferenceId

    if (vehicleReferenceId) {
      this.setItem("ls:activeVehicleReferenceId", vehicleReferenceId)
      let v = await criteriaStore.findCar({ vehicleReferenceId })
      if (v) {
        this.setActiveVehicle(v)
        if (this.activeVehicleProfile) {
          if (this.activeVehicleProfile.vehicleReferenceId === vehicleReferenceId) return
          this.activeVehicleProfile.setVehicleReferenceId(vehicleReferenceId)
          this.activeVehicleProfile.setVehicle(v)
          this.activeVehicleProfile.setGearBox(false, false)
          this.activeVehicleProfile.setConfiguration(false)
          this.activeVehicleProfile.setMileage(false)
          this.activeVehicleProfile.setBody(0)
          const firstRegistration = moment(v.productionYearFrom, "YYYY").toISOString()
          console.log({ firstRegistration })
          this.activeVehicleProfile.setFirstRegistrationDate(firstRegistration)
        }
      }
    }
  }

  setActiveVehicleClassId(vehicleClassId = "PKW") {
    console.log("ACTIVE====", vehicleClassId)
    let vehicleClass = this.vehicleClasses[vehicleClassId]
    if (vehicleClass) {
      this.activeVehicleClass = vehicleClass
      this.activeVehicleClassId = vehicleClassId
    }
    this.setActiveVehicleSeries(vehicleClassId)
    if (vehicleClassId !== this.vehicleClasses.CAR.id && vehicleClassId !== this.vehicleClasses.VAN.id) {
      this.clearVehicleProfile()
    }
    this.setItem("ls:vehicleClass", vehicleClassId)
  }

  setActiveVehicleProfileId(vehicleId) {
    if (vehicleId && vehicleId.vehicleId) {
      vehicleId = vehicleId.vehicleId
    }
    this.activeVehicleProfileId = vehicleId
    this.setItem("ls:vehicleProfileId", vehicleId)
    this.setActiveVehicleProfile(sessionStore.vehicleById.get(this.activeVehicleProfileId))
  }

  setActiveVehicleProfile(vehicleProfile) {
    this.activeVehicleProfile = vehicleProfile || new VehicleProfile({})

    if (vehicleProfile) {
      this.activeVehicleProfileId = vehicleProfile.vehicleId
      this.setItem("ls:vehicleProfileId", vehicleProfile.vehicleId)
      vehicleProfile.saveLocal && vehicleProfile.saveLocal()
      let vehicle = criteriaStore.getVehicleByProfile(vehicleProfile)
      const manufacturerKey = vehicleProfile.manufacturer || vehicleProfile.manufacturerKey

      manufacturerKey && this.setActiveManufacturerKey(manufacturerKey)
      if (vehicle) {
        this.setActiveVehicle(vehicle)
      }
    }

    this.activeVehicleProfile.fetchMeta()

  }

  setActiveQuality(quality) {
    this.activeQuality = quality
    this.setItem("ls:activeQuality", quality)
  }

  async setActiveVehicle(vehicleData, vehicleProfileId) {

    if (!vehicleData) this.setItem("ls:activeVehicleReferenceId", false)
    if (vehicleData && vehicleData.error) return null
    if (this.activeVehicle) {
      if (vehicleData && vehicleData.referenceId === this.activeVehicle.referenceId) return null
    }

    if (vehicleData === null) {
      this.activeVehicle = null
      this.activeVehicleReferenceId = null
    }
    if (vehicleProfileId === null) {
      this.activeVehicleProfileId = null
      this.activeVehicleProfile = null
    }
    vehicleProfileId && this.setActiveVehicleProfileId(vehicleProfileId)
    if (!vehicleData) {
      this.activeVehicle = false
    }
    if (vehicleData) {
      //TODO: MOVE ORM closer toward data fetching
      console.log("SET ACTIVE VEHICLE", vehicleData)
      let vehicle = new VehicleModel(vehicleData)
      this.activeVehicle = vehicle
      let referenceId = vehicle.referenceId || vehicle.vehicleReferenceId
      this.activeVehicleReferenceId = referenceId
      this.setItem("ls:activeVehicleReferenceId", referenceId)

      if (!vehicleProfileId && sessionStore.profile) {
        const hash = `${vehicle.hsn}-${vehicle.tsn}`
        let possibleVehicleProfile = sessionStore.profile.vehicleByReferenceId[referenceId] || sessionStore.profile.vehicleByHash[hash]
        if (possibleVehicleProfile) {
          this.setActiveVehicleProfile(possibleVehicleProfile)
        }
      }
      // TEST WITH LOGGED IN PROFILE
      this.activeVehicleProfile && this.activeVehicleProfile.setVehicle(vehicle)
      // fetch vehicle metadata
      console.log("FETCH VEHICLE META", referenceId)
      let vehicleMeta = await labourServiceStore.fetchVehicleMetadata(referenceId)
      let defaultBodyId = vehicleMeta.defaultBodyId
      this.activeVehicleMeta = vehicleMeta
      await labourServiceStore.fetchClusterList({
        vehicleReferenceId: referenceId,
        bodyId: defaultBodyId
      })

      if (this.activeManufacturer !== vehicle.manufacturerName) {
        vehicle.manufacturerName && this.setActiveManufacturerKey(vehicle.manufacturerName)
      }
    }
    else {
      this.setItem("ls:activeVehicleReferenceId", false)
    }
  }

  async setActiveVehicleMeta(data) {
    this.activeVehicleMeta = new VehicleMeta({ ...this.activeVehicleMeta, ...data })
    let bodyId = this.activeVehicleMeta.bodyId || this.activeVehicleMeta.defaultBodyId
    await labourServiceStore.fetchClusterList({
      vehicleReferenceId: this.activeVehicleReferenceId,
      bodyId: bodyId
    })

  }

  setActiveStoreId(cid) {
    console.log("STORE ID", cid)
    const id = `${cid}`.toUpperCase()
    const hasChanged = id !== this.activeStoreId
    const oldStoreId = this.activeStoreId

    // REPLACE STORE ID B/C those stores have been merged. Some permalinks might link them.
    let _id = id
    if (id === "ORTH_NAGEL_HALLE") _id = "NAGEL_SOHN_BORGHOLZHAUSEN"
    if (id === "NAGEL_SOHN_VERSMOLD") _id = "NAGEL_SOHN_BORGHOLZHAUSEN"
    let store = this.storeById.get(_id)

    Sentry.setContext("Store", {
      storeKey: id
    })

    if (store) {
      this.activeStoreId = store.key
      this.setItem("ls:activeStore", store.key)
      if (hasChanged) {

        if (oldStoreId && cartStore.products.length > 0) {
          toast.info("Warenkorb durch Filialenwechsel zurückgesetzt.")
          checkoutStore.hardReset()
          cartStore.setStoreLocation(store.key)
        }
      }

      this.activeManufacturerKey && this.activeStoreId && cmsStore.fetchServices()
      this.activeOrderTypes = store.orderTypes.filter(o => o.brand === this.activeManufacturerKey)

      cmsStore.fetchStoreProducts(null, store.key)
    }

    this.isOnlineStore = this.activeStoreId === "NACARMOS"
  }

  isPrimaryBrand(manufacturerKey) {
    return !!this.primaryBrandByKey[manufacturerKey]
  }

  isActiveStorePrimaryBrand(manufacturerKey) {
    return this.activeStore?.brands?.includes(manufacturerKey)
  }

  setActiveManufacturerKey(_manufacturerKey = "") {
    const manufacturerKey = _manufacturerKey && _manufacturerKey.toUpperCase()

    Sentry.setContext("Store", {
      storeKey: this.activeStoreId,
      manufacturerKey
    })
    if (this.activeManufacturerKey && this.activeManufacturerKey !== manufacturerKey) {
      if (this.activeVehicle) {
        if (this.activeVehicle.manufacturer !== manufacturerKey && this.activeVehicle.manufacturerName !== manufacturerKey) {
          this.setActiveVehicle(null)
          this.setActiveVehicleReferenceId(null)
        }
      }
      else {
        this.setActiveVehicle(null)
        this.setActiveVehicleReferenceId(null)

      }
    }

    if (this.activeManufacturerKey !== manufacturerKey) {
      this.activeManufacturerKey = manufacturerKey || ""
      this.activeManufacturer = criteriaStore.manufacturerByKey.get(this.activeManufacturerKey)
      this.setItem("ls:activeManufacturerKey", manufacturerKey)

      if (this.activeManufacturer) {
        this.activeManufacturer.prepare()
      }
      else {
        this.activeManufacturerKey = false
      }


      if (this.activeManufacturerKey && this.activeStoreId) {
        this.activeManufacturerKey && this.activeStoreId && cmsStore.fetchServices()
        const activeStore = this.storeById.get(this.activeStoreId)
        if (activeStore) {
          this.activeOrderTypes = activeStore.orderTypes.filter(o => o.brand === this.activeManufacturerKey)
        }
      }
    }
  }

  createBaseUrl({
    activeStoreId,
    activeManufacturerKey,
    activeVehicleReferenceId
  } = {}) {
    activeStoreId = activeStoreId || this.activeStoreId
    activeVehicleReferenceId = activeVehicleReferenceId || this.activeVehicleReferenceId
    activeManufacturerKey = activeManufacturerKey || this.activeManufacturerKey || "Alle"
    let vehiclePart = ""
    if (activeVehicleReferenceId) {
      vehiclePart = `/${activeVehicleReferenceId}`
    }
    // return `/standort/${activeStoreId}/${activeManufacturerKey}${vehiclePart}`
    return ``
  }

  getPathname(path = "", queryParams = {}) {
    return `${this.baseUrl}${path}?${queryString.stringify(queryParams)}`
  }

  getPath(path = "", queryParams = {}, replacements = false) {
    const query = { vehicleReferenceId: this.activeVehicleReferenceId, ...queryParams }
    return `${this.getPathname(path, query)}`
  }

  setActiveVehicleSeries(vehicleSeries) {
    // different request key? some vehicleSeries are grouped for this
    let vehicleClass = this.vehicleClasses[vehicleSeries]
    if (vehicleClass && vehicleClass.requestKey) {
      this.activeVehicleClassIdRequestKey = vehicleClass.requestKey
    }
    else {
      this.activeVehicleClassIdRequestKey = vehicleSeries
    }
    cmsStore.createMetaData()
  }

  sortByDistance({
    lat,
    lng
  }) {
    const stores = this.stores.map(store => {
      store.distance = distance(lat, lng, store.latitude, store.longitude)
      return store
    })
    this.stores = sortBy(stores, "distance")
  }

  get() {

    setTimeout(() => {
      if (!this.stores || this.stores.length === 1) {
        this.isError = true
        this.ready = true
      }
    }, 1000 * 30)
    this.isError = false
    let criteriaPromise = criteriaStore.loadManufacturers()
    let storePromise = fetchWithTimeout(`${config.API_BASE}/stores`, { timeout: 10000 })
      .then(res => res.json())
      .then(stores => stores.filter(store => store.key !== "NAGEL_SERVICES" && store.key !== "NAGELCARGROUP"))
      .then(stores => stores.filter(storeHasLongitude)) //
      .then(stores => {
        // // --> console.log( { stores } )
        stores = stores.filter(store => {
          store.manufacturerKeys = store.brands.map(e => e.toUpperCase())

          store.repairClassByKey = {}
          store.repairClasses.forEach(rp => {
            store.repairClassByKey[rp] = rp
          })

          if (store.website.includes("nagel")) {
            store.logoUrl = "/shop/img/logo-auto-nagel-w.png"
          }
          else {
            store.logoUrl = "/shop/img/logo-auto-nagel-w.png"
          }

          // SET ONLINE FILIALE. USED AS DELIVERY PROVIDER
          if (store.key === "NACARMOS") {
            this.onlineStore = store
            this.storeById.set(store.key, store)
          }
          return true
        })

        this.storesByRegion = new Map()
        stores = stores.map(storeData => new StoreModel(storeData))
        stores.forEach(store => {
          let regionBucket = this.storesByRegion.get(store.region) || []
          regionBucket.push(store)
          this.storesByRegion.set(store.region, regionBucket)
          store.brands.forEach(b => {
            let brand = b.toUpperCase()
            // store.brands.push( brand )
            let brandBucket = this.storesByBrand.get(brand) || []
            brandBucket.push(store)
            this.storesByBrand.set(brand, brandBucket)
          })
          this.storeById.set(store.key, store)
        })

        this.primaryBrands = _.uniq([...this.storesByBrand.keys()].filter(k => k !== "ALLE"))
        this.primaryBrands.forEach(brandKey => {
          this.primaryBrandByKey[brandKey] = brandKey
        })

        // VERSMOLD & HALLE are now BORG. resistance is futile
        this.storeById.set("ORTH_NAGEL_HALLE", this.storeById.get("NAGEL_SOHN_BORGHOLZHAUSEN"))
        this.storeById.set("NAGEL_SOHN_VERSMOLD", this.storeById.get("NAGEL_SOHN_BORGHOLZHAUSEN"))

        // CHECK IF STORE ACTUALLY EXISTS
        if (this.activeStoreId) {
          let store = this.storeById.get(this.activeStoreId)
          if (!store) {
            this.setActiveStoreId("NACARMOS")
          }
        }

        // this.regions = [...this.storesByRegion.values()].map( e => e[0] )
        this.regions = Object.keys(this.storesByRegion.toJSON())

        this.stores = stores
        this.searcher = new FuzzySearch(this.stores, ["postalCode", "city", "name", "region", "street"], {
          caseSensitive: false
        })
        if (this.hasGeoData) {
          this.requestGeoData()
        }

        // --> console.log( {stores, storeById: this.storeById, regions: this.regions} )
        return stores
      })
      .catch(err => console.warn(err))

    return Promise.all([criteriaPromise, storePromise])
      .then(res => {
        this.ready = true
        return res
      }).catch(err => {
        this.ready = true
        this.clear()
        this.isError = true

        console.error(err)
      })

  }


  clear() {
    // Clear localStorage and sessionStorage while preserving ccm_consent
    const ccmConsent = localStorage.getItem('ccm_consent');

    // Clear localStorage
    localStorage.clear();

    // Restore ccm_consent if it existed
    if (ccmConsent) {
      localStorage.setItem('ccm_consent', ccmConsent);
    }

    // Clear sessionStorage
    sessionStorage.clear();
  }
}

function distance(lat1, lon1, lat2, lon2) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0
  }
  else {
    let radlat1 = Math.PI * lat1 / 180
    let radlat2 = Math.PI * lat2 / 180
    let theta = lon1 - lon2
    let radtheta = Math.PI * theta / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    dist = dist * 1.609344
    return dist
  }
}

const storeHasImage = s => s.image
const storeHasLongitude = s => s.longitude
