// // import locationStore from "store/locationStore";
import { slugify } from "utils/slugify"
import { first, sortBy } from "lodash-es"
import { ServiceProductModel } from "model/ServiceProductModel"
import { action, computed, observable, makeObservable } from "mobx"
import labourServiceStore from "../page/LabourTimeServicePage/labourServiceStore"
// import searchStore from "./searchStore";
import shop from "store/shop"
import config from "./store.config"
import QUERY_SERVICES from "./graphql/QUERY_SERVICES"
import QUERY_VIP from "./graphql/QUERY_VIP"
import StoreProductModel from "../model/StoreProductModel";
import moment from "moment"

const API_KEY = process.env.REACT_APP_PIMCORE_KEY || "6fad107720155a70d6d052e690af3d88"
const PIMCORE_URL = process.env.REACT_APP_PIMCORE_URL || process.env.REACT_APP_API_URL + "/pimcore"
const cache = {}

console.log("PIMCORE_URL", PIMCORE_URL)
const DEFAULT_MANUFACTURER_KEY = "NACARMOS"
const DEFAULT_STORE_ID = "NACARMOS"

// .filter( c => c.id !== "H20" ) /// REMOVE ?
// .filter( c => c.id !== "H9999" ) // REMOVE RABATTE
// .filter( c => c.id !== "H55" ) // REMOVE BOXENSTOPP
const metaDataByGroup = {
  "H9999": {
    hideInNavigation: true
  }
}


const BLOCK_PRODUCT = `
               mainProduct {
                        id
                        name: description
                        summaryHtml
                        compatibleBrands
                        manufacturer
                        slug
                        partNumber
                        pricing {
                            location
                            stock
                            price
                            originalPrice
                        }
                        vehicleSeries
                        images
                        categories {
                            fullName
                            subCategory{
                                id
                                slug
                                name
                            }
                            mainCategory{
                                id
                                slug
                                name
                            }
                        }
                        description
                    }
                    variants
                    variantFields
`

// https://nagel.staging.pretzelhands.com/pimcore-graphql-webservices/packageServices?apikey=
export default class CmsStore {
  onlyAppointmentProduct = false
  SRV_SPEDITION = null
  SRV_ABHOLUNG = null
  SRV_MONTAGE = null
  SRV_MONTAGEAB18 = null
  SRV_HU_VORABCHECK = null
  SRV_HU_MIT_AU = null
  categories = []
  hiddenCategories = []
  primaryCategories = []
  storeCategories = {}
  categoryById = {}
  productById = {}
  productByWorkPositionNumber = new Map()
  clusterByGroup = {}
  products = []
  storeProductById = new Map()
  storeMainProductById = new Map()
  storeProducts = []
  ready = false

  featuredById = {}
  productsByCategory = {}
  featuredByWorkPositionNumber = {}
  featuredProducts = []

  labourServices = []

  countByCategoryHash = {}

  constructor() {
    makeObservable(this, {
      onlyAppointmentProduct: observable,
      SRV_SPEDITION: observable,
      SRV_ABHOLUNG: observable,
      SRV_MONTAGE: observable,
      SRV_MONTAGEAB18: observable,
      SRV_HU_VORABCHECK: observable,
      SRV_HU_MIT_AU: observable,
      categories: observable,
      primaryCategories: observable,
      storeCategories: observable,
      categoryById: observable,
      productById: observable,
      storeProductById: observable,
      storeProducts: observable,
      storeMainProductById: observable,
      productByWorkPositionNumber: observable,
      clusterByGroup: observable,
      products: observable,
      ready: observable,
      featuredById: observable,
      productsByCategory: observable,
      featuredByWorkPositionNumber: observable,
      featuredProducts: observable,
      labourServices: observable,
      loadCategories: action,
      lastSeenProducts: observable,
      addLastSeenProduct: action,
      loadServices: action,
      fetch: action,
      createMetaData: action,
      handleProducts: action,
      handleServiceResponse: action,
      priceCheapestTireMontage: computed
    })
  }

  get priceCheapestTireMontage() {
    let p = this.products.filter(s => s.workPositionNumber === "WEBP40-0018")
    if (!p) return false
    if (p) {
      const montageService = p[0]
      return montageService && montageService.price
    }
    return false
  }

  async hydrate() {
    await this.loadCategories()
    if (locationStore.isOnlineStore) {
      await this.fetchServices(DEFAULT_MANUFACTURER_KEY, DEFAULT_STORE_ID)
    }
    this.ready = true
  }

  async loadCategories() {
    const {
      mainCategories,
      subCategories
    } = await this.getCategories()
    // const { mainCategories, subCategories } = categoryData
    mainCategories
      .forEach(m => {
        m.isMaincategory = true
      })

    this.categories = [...sortBy(mainCategories, "sortOrder"), ...sortBy(subCategories, "sortOrder")].map(stub => {
      stub.slug = slugify(stub.name)
      return stub
    })

    this.categories.forEach(c => {
      c.meta = metaDataByGroup[c.id] || {}
      this.categoryById[c.id] = c
    })

    const today = moment()
    this.primaryCategories = sortBy(this.categories
      .filter(c => c.isMaincategory)
      .filter(c => c.id !== "H20") /// REMOVE ?
      .filter(c => c.id !== "H9999") // REMOVE RABATTE
      .filter(c => c.id !== "H60") // REMOVE ANGEBOTE
      .filter(c => {
        if (c.from && c.to) {
          if (moment(c.from).isBefore(today) && moment(c.to).isAfter(today)) {
            return true
          }
          return false
        }
        return true
      }), "sortOrder")

    this.allCategories = this.categories
    this.createMetaData()
  }

  loadServices(path, data) {
    locationStore.setLoading(true)
    return this.fetch(path, data)
      .then(res => this.handleServiceResponse(res, data))
      .then(res => {
        this.onlyAppointmentProduct = first(this.products.filter(e => e.workPositionNumber.includes("00-0062")))
        // TODO RENAME
        this.SRV_HU_VORABCHECK = first(this.products.filter(e => e.workPositionNumber.includes("00-0007")))
        this.SRV_HU_MIT_AU = first(this.products.filter(e => e.workPositionNumber.includes("00-0006")))
        searchStore.setupSearch(locationStore.activeVehicleClassId, this.products, this.categories)
        locationStore.setLoading(false)
        return res
      })
  }

  fetchFeaturedClusters() {
    let promise = shop.cachedFetch(`${config.PIMCORE_URL}/api/clusters`)
      .then(clusters => {
        this.clusterByGroup = {}
        clusters.forEach(cluster => {
          try {
            this.clusterByGroup[cluster.groups[0]?.mainGroup.id] = this.clusterByGroup[cluster.groups[0]?.mainGroup.id] || []
            this.clusterByGroup[cluster.groups[0]?.mainGroup.id].push(cluster)
          } catch (e) {
            // forfeit clusters without mainGroup
          }
        })
        return clusters
      })

    return promise
  }

  buildStoreCategoryTree(products) {
    const categoryById = new Map()
    products.forEach(product => {
      categoryById.set(product.subCategoryId, {
        id: product.subCategoryId,
        name: product.subCategoryName,
        slug: slugify(product.subCategoryName),
      })
    })
    return categoryById
  }


  fetchStoreProductSingle(productId, storeKey = locationStore.activeStoreId) {
    // language=GraphQL
    const QUERY_STORE_PRODUCT = `query {
            getMerchandise(id: "${productId}", location: "${storeKey}"){
                ${BLOCK_PRODUCT}
            }
        } `

    return this.fetch("/pimcore-graphql-webservices/packageServices", {
      query: QUERY_STORE_PRODUCT,
    }).then(res => this.handleProducts(res))
      .then(products => products[0])
  }




  fetchStoreProducts(category = "Store/Zubehör", storeKey) {
    console.log("FETCH", category, storeKey)
    return this.fetch("/pimcore-graphql-webservices/packageServices", {
      // language=GraphQL
      query: `query {
                getMerchandise(location : "${storeKey}") {
                    ${BLOCK_PRODUCT}
                }
            }
            `,
      variables: {}
    }).then(res => this.handleProducts(res))
  }

  lastSeenProducts = new Map()

  addLastSeenProduct = (product) => {
    this.lastSeenProducts.set(product.productID, product)
  }

  handleProducts = (res) => {
    console.log({ res })
    if (!res.data) return []
    if (!res.data.getMerchandise) return []
    let products = res.data.getMerchandise.map(data => {
      const product = new StoreProductModel({
        ...data,
        mainProduct: Object.assign(data.mainProduct, data.variants[0]),
      })
      product.variants.forEach(variant => {
        variant.productID = variant.id
        const productVariant = new StoreProductModel({
          ...data,
          mainProduct: Object.assign(data.mainProduct, variant),
        })
        this.storeProductById.set(productVariant.id, productVariant)
        return productVariant
      })

      this.storeProductById.set(product.id, product)
      this.storeMainProductById.set(product.id, product)
      return product
    })

    this.storeProducts = [...this.storeProductById.values()]
    return products
  }

  fetchFeatured({
    manufacturerKey,
    storeId
  }) {
    this.featuredById = {}
    this.featuredByWorkPositionNumber = {}
    this.featuredProducts = []
    return this.fetch("/pimcore-graphql-webservices/packageServices", {
      query: QUERY_VIP,
      variables: {
        brand: manufacturerKey,
        storeId: storeId
      }
    }).then(res => {
      return res.data.getPackageServices.map(data => new ServiceProductModel(data))
    })
      .then(products => {
        products.forEach(product => {
          product.isVIP = true
          this.featuredProducts.push(product)
          this.featuredByWorkPositionNumber[product.id] = product
          this.featuredById[product.id] = product
          this.productsByCategory[product.mainCategory] = this.productsByCategory[product.mainCategory] || []
          this.productsByCategory[product.mainCategory].push(product)
          //TODO: THIS IS A RACE CONDITION WITH regular fetch
          this.productById[product.productID] = product
          this.productByWorkPositionNumber.set(product.workPositionNumber, product)
        })
        return products
      })
  }

  previewLoad(activeManufacturerKey = locationStore.activeManufacturerKey || "NACARMOS", activeStoreId = locationStore.activeStoreId, vehicleClassId = locationStore.activeVehicleClassId) {

    let manufacturerKey = activeManufacturerKey.toUpperCase()

    const storeHasBrand = locationStore.activeStore && locationStore.activeStore.brands.map(e => e.toUpperCase())
      .includes(activeManufacturerKey)
    if (!storeHasBrand) {
      manufacturerKey = "NACARMOS"
    }

    let vehicleClass = locationStore.vehicleClasses[vehicleClassId]
    let activeVehicleClassIdRequestKey = vehicleClassId
    if (vehicleClass && vehicleClass.requestKey) {
      activeVehicleClassIdRequestKey = vehicleClass.requestKey
    }
    let vehicleType = activeVehicleClassIdRequestKey

    // INVALID KEY?
    if (!(vehicleType in locationStore.vehicleClasses)) {
      vehicleType = "CAR"
    }

    return this.fetch("/pimcore-graphql-webservices/packageServices", {
      query: QUERY_SERVICES,
      variables: {
        location: activeStoreId,
        vehicleType,
        brand: manufacturerKey
      }
    })
  }

  fetch(path, data, method = "POST") {
    let body = data ? JSON.stringify(data) : null
    let url = `${PIMCORE_URL}${path}?apikey=${API_KEY}`
    const cacheKey = `${url}-${body}`

    if (cache[cacheKey]) {
      return cache[cacheKey]
    }

    let promise = fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body
    }).then(res => res.json())

    cache[cacheKey] = promise
    return promise
  }

  getSubcategoriesForCategory(categoryId) {
    let products = this.products.filter(p => p.tags.includes(categoryId))
    let categories = {}

    products.forEach(p => {
      p.tags.forEach(tag => {
        categories[tag] = true
      })
    })

    labourServiceStore.clusters
      .filter(cluster => cluster.mainGroupId === categoryId)
      .map(cluster => {
        categories[cluster.subGroupId] = true
      })

    return Object.keys(categories)
      .map(tag => this.categoryById[tag])
      .filter(e => e)
  }

  createMetaData(vehicleType = locationStore.activeVehicleClassId) {
    if (!this.allCategories) return
    const countByCategoryHash = {}
    const productsByCategoryHash = {}
    this.allCategories.forEach(category => {
      category.count = 0
    })

    labourServiceStore.clusters.map(cluster => {
      if (!cluster.mainGroupId) return null
      let hash = `${cluster.mainGroupId}-${cluster.subGroupId}`
      let categoryHashCount = countByCategoryHash[hash] || 0
      categoryHashCount++
      countByCategoryHash[hash] = categoryHashCount

      const products = productsByCategoryHash[hash] || []
      products.push(cluster)
      productsByCategoryHash[hash] = products

      let hashMainCategory = `${cluster.mainGroupId}`
      let categoryHashCountMainCategory = countByCategoryHash[hashMainCategory] || 0
      categoryHashCountMainCategory++
      countByCategoryHash[hashMainCategory] = categoryHashCountMainCategory

      // Don't add clusters to productGroup
      // {
      //     const products = productsByCategoryHash[ hashMainCategory ] || []
      //     products.push( cluster )
      //     productsByCategoryHash[ hashMainCategory ] = products
      // }
    })

    this.products.forEach(product => {
      const isActive = vehicleType === product.vehicleType
      // CROSS REFERENCE WITH CATEGORY DATA => MAINCATEGORIES MIGHT NOT ALWAYS START WITH H.
      let mainCategories = product.tags.filter(tag => tag[0] === "H")
      if (isActive) {
        product.tags.forEach(tag => {
          if (tag[0] === "U") {
            mainCategories.forEach(mainCat => {
              let hash = `${mainCat}-${tag}`
              let categoryHashCount = countByCategoryHash[hash] || 0
              countByCategoryHash[hash] = 1 + categoryHashCount
              // ADD TO CATEGORY HASH GROUP
              {
                const products = productsByCategoryHash[hash] || []
                products.push(product)
                productsByCategoryHash[hash] = products
              }
            })
          }

          let categoryHashCount = countByCategoryHash[tag] || 0
          categoryHashCount++
          countByCategoryHash[tag] = categoryHashCount
          // ADD TO CATEGORY HASH GROUP
          {
            const products = productsByCategoryHash[tag] || []
            products.push(product)
            productsByCategoryHash[tag] = products
          }
        })
      }
    })
    // MOBX RE-RENDER ARRAY :/ :D
    this.categories = this.allCategories.map(e => e)
    this.hiddenCategories = this.allCategories.filter(e => !e.showCategory).map(e => e)
    this.countByCategoryHash = countByCategoryHash
    this.productsByCategoryHash = productsByCategoryHash
    // this.categories = categories
  }

  async handleServiceResponse(json, data = { variables: {} }) {
    // RESET FIXED SERVICES
    this.SRV_SPEDITION = false
    this.SRV_ABHOLUNG = false
    this.SRV_MONTAGE = false
    this.SRV_MONTAGEAB18 = false
    this.products = []
    this.productById = {}
    this.productByWorkPositionNumber = new Map()


    const packages = json?.data?.labourPackages || []
    let labourServices = packages
      .map(e => {
        const product = new ServiceProductModel(e)
        if (product.brand === locationStore.activeManufacturerKey && product.workPositionNumber === "WEB-LIEFERUNG-ERSATZTEIL") {
          this.SRV_SPEDITION = product
        }
        if (product.brand === locationStore.activeManufacturerKey && product.workPositionNumber === "WEB-LIEFERUNG") {
          this.SRV_SPEDITION = product
        }
        if (product.brand === locationStore.activeManufacturerKey && product.workPositionNumber === "WEB-ABHOLUNG") {
          this.SRV_ABHOLUNG = product
        }
        if (product.brand === locationStore.activeManufacturerKey && product.workPositionNumber === "WEB-MONTAGE") {
          this.SRV_MONTAGE = product
        }
        if (product.brand === locationStore.activeManufacturerKey && product.workPositionNumber === "WEB-MONTAGEAB18") {
          this.SRV_MONTAGEAB18 = product
        }

        // if ( this.SRV_SPEDITION ) console.error( "KEIN SPEDITIONSPRODUKT VORHANDEN" )
        // if ( this.SRV_ABHOLUNG ) console.error( "KEIN IN FILIALE ABHOLEN PRODUKT VORHANDEN" )
        // if ( this.SRV_MONTAGE ) console.error( "KEIN IN FILIALE MONTIEREN PRODUKT VORHANDEN" )
        this.productById[product.productID] = product
        this.productByWorkPositionNumber.set(product.workPositionNumber, product)
        this.products.push(product)
        return product
      })

    this.labourServices = labourServices
    this.productsByCategory = {}
    let products = sortBy(json.data.getPackageServices, "sortOrder")
      .map(e => {
        // FILTER BRAND IN FRONTEND
        if (e.brand !== data.variables.brand) {
          console.info(`Invalid Brand for Product: ${e.brand}, ${e.id}. Should be ${data.variables.brand}`)
          return null
        }

        // Hide services that have no pricing
        if (!e || !e.pricing || !e.pricing.length) {
          return null
        }

        const product = new ServiceProductModel(e)
        this.productById[product.productID] = product
        this.productByWorkPositionNumber.set(product.workPositionNumber, product)
        this.productsByCategory[product.mainCategory] = this.productsByCategory[product.mainCategory] || []
        this.productsByCategory[product.mainCategory].push(product)
        this.products.push(product)
        return product
      })
      .filter(e => e)

    this.allCategories && this.allCategories.length > 0 && this.createMetaData()
    return products
  }

  // CRUTCH METHOD - TODO: REMOVE w/ Richard
  getSubcategoryIdByName(name) {
    return this.categories.find(e => e.name === name)?.id
  }

  getCategories() {
    return this.fetch(`/pimcore-graphql-webservices/packageServices`, {
      query: `query {
                getMerchandiseCategoryTree: getCategoriesForParts # TODO RENAME
                getCategories {
                    mainCategories {
                      showInSearch
                      showCategory
                      id
                      from
                      to
                      name
                      sortOrder
                        seo {
                          title
                          description
                        }
                    }
                        subCategories{
                            id
                            name
                            sortOrder
                        }
                    }
                }
            `
    })
      .then(async json => {
        try {
          this.storeCategories = json.data.getMerchandiseCategoryTree[0].children // TODO: subcategories for "store

        } catch (err) {
          this.storeCategories = [
            {
              "id": 23775336,
              "name": "Store",
              "count": 26,
              "children": [
                {
                  "id": "U204",
                  "name": "Merchandise",
                  "count": 6
                },
                {
                  "id": "U32",
                  "name": "Zubehör",
                  "count": 20
                }
              ]
            }
          ]
        }

        this.storeCategories = [...this.storeCategories, {
          id: "WPK",
          count: 20,
          name: "Kompletträder"
        }, {
          id: "TIRE",
          count: 20,
          name: "Reifen"
        }]
        return json.data.getCategories
      })
  }

  async fetchServices(activeManufacturerKey = locationStore.activeManufacturerKey || "NACARMOS", activeStoreId = locationStore.activeStoreId, vehicleClassId = locationStore.activeVehicleClassId) {
    let manufacturerKey = activeManufacturerKey.toUpperCase()

    const storeHasBrand = locationStore.activeStore && locationStore.activeStore.brands.map(e => e.toUpperCase())
      .includes(activeManufacturerKey)
    if (!storeHasBrand) {
      manufacturerKey = "NACARMOS"
    }

    let vehicleClass = locationStore.vehicleClasses[vehicleClassId]
    let activeVehicleClassIdRequestKey = vehicleClassId
    if (vehicleClass && vehicleClass.requestKey) {
      activeVehicleClassIdRequestKey = vehicleClass.requestKey
    }
    let vehicleType = activeVehicleClassIdRequestKey

    // INVALID KEY?
    if (!(vehicleType in locationStore.vehicleClasses)) {
      vehicleType = "CAR"
    }

    const cluster = await this.fetchFeaturedClusters()
    // console.log("FETCH SERVICES", { cluster, manufacturerKey, vehicleClassId, activeVehicleClassIdRequestKey,
    // activeStoreId })
    const services = await this.loadServices("/pimcore-graphql-webservices/packageServices", {
      query: QUERY_SERVICES,
      variables: {
        location: activeStoreId,
        vehicleType,
        brand: manufacturerKey
      }
    }, activeStoreId, manufacturerKey)

    return services
  }
}
