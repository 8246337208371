import moment from "moment"
import { action, observable, makeObservable } from "mobx"
import _ from "lodash-es"
import config from "store/store.config"
import queryString from "query-string"
import shop from "store/shop"

import { toast } from "react-toastify"

const state = {
  LOADING: "eLoading",
  ERROR: "eError",
  READY: "eReady"
}

export class Appointment {
  constructor({
    timeSlot,
    replacementVehicles,
    isBoxenstopp = false,
    isFallback = false
  }) {
    Object.assign(this, timeSlot)
    if (!timeSlot) {
      throw new Error("Invalid Timeslot")
    }

    this.isFallback = isFallback
    this.isBoxenstopp = isBoxenstopp
    this.replacementVehicles = replacementVehicles
    this.checkInTime = moment(timeSlot.timeSlot.from)
    this.checkOutTime = moment(timeSlot.timeSlot.until)
    this.isSameDay = this.isBoxenstopp || this.checkInTime.isSame(this.checkOutTime, "day")
    if (!this.isBoxenstopp) {
      if (this.checkOutTime.isBefore(moment(this.checkInTime).add(4, "hours"))) {
        this.checkOutTime = moment(this.checkInTime).add(4, "hours")
        if (this.checkOutTime.isAfter(moment(this.checkOutTime).hours(17))) {
          // WRAP AROUND DAY
          this.checkOutTime.add(1, "day").hour(11)
        }
      }
    }

    this.date = this.checkInTime.date()
    this.day = this.checkInTime.day()
    this.startOfDay = moment.utc(this.checkInTime).startOf("day")
    this.startOfDayString = moment.utc(this.checkInTime).startOf("day").toISOString()
    this.weekday = this.checkInTime.format("dddd DD.MM.")
    // console.log(this.isBoxenstopp, this.checkInTime, {timeSlot})

  }
}

export default class AppointmentStore {
  date = moment()
  selectedAppointment = false
  appointmentsAvailable = null
  specialAppointments = null
  byStore = new Map()
  state = "eLoading"
  appointmentsByOrder = new Map()
  /**
   *
   * @param store
   * @param ServicesOrProducts ids, csv i.e. (SRV1,SRV2)
   * @returns {Promise<Response | never>}
   */
  expressCache = new Map()

  constructor() {
    makeObservable(this, {
      date: observable,
      selectedAppointment: observable,
      appointmentsAvailable: observable,
      specialAppointments: observable,
      byStore: observable,
      state: observable,
      appointmentsByOrder: observable,
      setAppointment: action.bound,
      fetchExpressDates: action,
      postExpressReservationAppointment: action,
      postExpressAppointment: action,
      setOrder: action,
      fetch: action,
      fetchAppointments: action,
      setDate: action.bound
    })
  }


  getAppointmentsForCart({
    storeKey,
    brand,
    cartId,
    from,
    until,
    vehicleClass,
    wantsAssistance,
    wantsReplacementVehicle,
    dontExpandRangeOnFailure = false,
    isBoxenstopp = false,
  }) {

    const url = isBoxenstopp ? `v2/appointments/search/boxenstopp` : `v2/appointments/search`

    return shop.fetch(url, {
      method: "POST",
      body: JSON.stringify({
        storeKey,
        brand,
        cartId,
        from: moment(from).startOf("day").toISOString(),
        until: moment(until).endOf("day"),
        class: vehicleClass,
        wantsAssistance,
        wantsReplacementVehicle
      })
    })
      .then(res => res.json())
      .then(data => {
        // check if we have a appointments - if not, request new ones with 2 weeks in the future
        if (!dontExpandRangeOnFailure && (!data || data.timeSlots.length === 0)) {
          const untilExtended = moment(until).add(4, "weeks").endOf("day").toISOString()
          toast(`Keine Termine gefunden. Suche nach Terminen bis ${moment(untilExtended).format("DD.MM.YYYY")}`)
          return shop.fetch(`v2/appointments/search`, {
            method: "POST",
            body: JSON.stringify({
              storeKey,
              brand,
              cartId,
              from: moment(from).startOf("day").toISOString(),
              until: untilExtended,
              class: vehicleClass,
              wantsAssistance,
              wantsReplacementVehicle
            })
          })
            .then(res => res.json())
        }
        return data
      })
      .then(handleAppointmentRequest)
      .then(appointmentRequest => {
        this.appointmentsAvailable = appointmentRequest
        this.byStore.set(appointmentRequest.store, appointmentRequest.byDate)
        this.state = state.READY
        return appointmentRequest
      })
      .catch(err => {
        console.error("Error Loading Appointments:", err)
        this.state = state.ERROR
        console.warn(`Invalid Appointments`)
      })
  }


  setAppointment(appointment) {
    this.selectedAppointment = appointment
  }

  fetchExpressDates({
    store = locationStore.activeStoreId,
    servicesOrProducts,
    favoredCheckinDate = moment().toISOString()
  }) {
    const search = queryString.stringify({
      store,
      services: servicesOrProducts,
      favoredCheckinDate,
      manufacturer: locationStore.activeManufacturerKey
    })
    const cachedPromise = this.expressCache.get(search)
    if (cachedPromise) return cachedPromise

    // http://ncggttestapplication.versatio.de:7654/api/express-orders?Store=STOPKA_DINSLAKEN&ServicesOrProducts=SRV368
    let promise = fetch(`${config.API_BASE}/express-orders?${search}`, {
      method: "GET",
      headers: {
        "Authorization": sessionStore.authorization
      }
    })
      .then(res => res.json())
      .then(res => res[0])
      .then(handleAppointmentRequest)

    this.expressCache.set(search, promise)
    return promise
  }

  postExpressReservationAppointment(body) {

    if (body.shoppingCartID < 0) {
      console.error("Invalid shoppingCartID", body)
    }

    //REMOVE empty or false attributes. Backend is happier with null or no attribute
    if (body) {
      if (body.vehicle) {
        if (!body.vehicle.vehicleReferenceId) delete body.vehicle.vehicleReferenceId
        if (!body.vehicle.manufacturer) delete body.vehicle.manufacturer
        if (!body.vehicle.manufacturerKeyNumber) delete body.vehicle.manufacturerKeyNumber
        if (!body.vehicle.typeKeyNumber) delete body.vehicle.typeKeyNumber
        if (!body.vehicle.vehicleIdentificationNumber) delete body.vehicle.vehicleIdentificationNumber
      }
    }

    // attach employeeName
    if (sessionStore.originalUserProfile) {
      body.employeeName = sessionStore.originalUserProfile.preferredEmployeeName
    }

    return fetch(`${config.API_BASE}/express-orders/reservations`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Authorization": sessionStore.authorization,
        "IdentityUserId": sessionStore.identityUserId,
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        // // --> console.log( { express: res } )
        return res
      })
  }

  /**
   * This one supports services only (SRVxxxx)
   * @param body
   * @returns {Promise<*>}
   */
  postExpressAppointment(body) {
    return fetch(`${config.API_BASE}/express-orders`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Authorization": sessionStore.authorization,
        "IdentityUserId": sessionStore.identityUserId,
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        // // --> console.log( { express: res } )
        return res
      })
  }

  setOrder(order, {
    onBehalfOf,
    orderRef,
    favoredCheckinDate,
    favoredCheckinTime
  } = {}) {
    // // --> console.log( " appointment store w/ order", { order, favoredCheckinDate, onBehalfOf } )
    if (order) {
      return this.fetchAppointments(order, {
        onBehalfOf,
        orderRef,
        favoredCheckinDate,
        favoredCheckinTime
      })

      let { boxenstop } = config.store
      if (boxenstop) {
        //  LOAD SPECIAL APPOINTMENTS
        let dates = boxenstop.map(month => moment.utc(month, "DD.MM.YYYY").add(6, "hour"))
        console.log("dates", dates)
        let p = dates.map(date => {
          const boxenstoppDate = date.toISOString()
          return this.fetch({
            order,
            favoredCheckinDate: boxenstoppDate,
            onBehalfOf,
            favoredCheckinTime
          })
            .then(data => {
              let appointments = data.appointments.map(event => new Appointment({
                timeSlot: event,
                date,
                replacementVehicles: [],
                isBoxenstopp: !!data.foundBoxenstoppAppointments
              }))
                .filter(appointment => {
                  return date.isSame(appointment.startOfDay, "day")
                })
              // // --> console.log( { date, appointments } )
              return {
                date,
                appointments
              }
            }).catch(err => {
              console.error("Error fetching appointments", err)
              return {
                date,
                appointments: []
              }
            })
        })

        Promise.all(p).then(res => {
          this.specialAppointments = res
        })
      }

    }
  }

  fetch = ({
    order,
    favoredCheckinTime,
    orderRef,
    favoredCheckinDate,
    onBehalfOf
  }) => {
    const {
      store,
      orderNumber
    } = order
    let data = {
      store,
      order: orderNumber,
      orderRef,
      favoredCheckinTime,
      favoredCheckinDate
    }

    const options = {
      headers: {
        "Authorization": sessionStore.authorization
      }
    }

    let p

    if (onBehalfOf) {
      data = { ...data, ...onBehalfOf }
      const params = queryString.stringify(data)
      p = fetch(`${config.URL_BASE}api/appointments/on-behalf-of?${params}`, options)
    } else {
      const params = queryString.stringify(data)
      p = fetch(`${config.API_BASE}/appointments?${params}`, options)
    }

    return p
      .then(res => res.json())
      .then(res => {
        // // --> console.log( "APPOINTMENTS", res )
        return res
      })
      .then(res => res[0])
  }

  fetchAppointments = (order, {
    favoredCheckinDate,
    orderRef,
    onBehalfOf
  } = {}) => {
    let {
      store,
      orderNumber
    } = order
    this.state = state.LOADING
    // // --> console.log( `store, ordernumber`, { favoredCheckinDate }, store, orderNumber )
    if (!store || !orderNumber) return
    return this.fetch({
      order,
      onBehalfOf,
      orderRef,
      favoredCheckinDate
    })
      .then(handleAppointmentRequest)
      .then(appointmentRequest => {
        this.appointmentsAvailable = appointmentRequest
        this.byStore.set(appointmentRequest.store, appointmentRequest.byDate)
        this.state = state.READY
        return appointmentRequest
      })
      .catch(err => {
        console.error("Error Loading Appointments:", err)
        this.state = state.ERROR
        console.warn(`Invalid Appointments`)
      })
  }

  setDate(date) {
    this.date = date
  }
}

const sortByDate = (d0, d1) => {
  if (d0.checkInTime.isBefore(d1.checkInTime)) {
    return 1
  }
  return -1
}

export { sortByDate }

const handleAppointmentRequest = (response) => {
  const {
    timeSlots,
    replacementVehicles
  } = response
  const replacementVehicleById = new Map(replacementVehicles
    .map(vehicle => [vehicle.vin, vehicle]))
  let dates = timeSlots.map(timeSlot => {
    timeSlot.vehicles = timeSlot.availableVehicles
      .map(vehicleStub => {
        const { vin } = vehicleStub
        return replacementVehicleById.get(vin)
      })
      .filter(e => e)

    return new Appointment({
      isBoxenstopp: !!response.foundBoxenstoppAppointments,
      timeSlot,
      replacementVehicles,
      isFallback: response.isFallback,
    })
  })
    .sort(sortByDate)
  const byDate = _.groupBy(dates, "startOfDay")

  // parse out vehicles
  return {
    timeSlots,
    byDate
  }
}


