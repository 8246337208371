import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from "react-toastify"
import shop from "store/shop"


export default class HashCampaignStore {
  campaigns = [];
  loading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  // Fetch all campaigns
  async fetchCampaigns() {
    this.loading = true;
    this.error = null;

    try {
      const response = await shop.fetch('/v1/customer/hash/campaign');
      const data = await response.json();

      runInAction(() => {
        this.campaigns = data;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
      });
    }
  }

  // Create new campaign
  async createCampaign(campaignData) {
    this.loading = true;
    this.error = null;

    try {
      const response = await shop.fetch(`/v1/customer/hash/campaign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(campaignData),
      });

      const newCampaign = await response.json();

      runInAction(() => {
        this.campaigns.push(newCampaign);
        this.loading = false;
      });
      toast.success("Campaign created successfully!")
      return newCampaign;
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
        toast.error("Error creating campaign. Please try again.")
      });
      throw error;
    }
  }

  // Update campaign
  async updateCampaign(campaignId, updates) {
    this.loading = true;
    this.error = null;

    try {
      const response = await shop.fetch(`/v1/customer/hash/campaign`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updates),
      });

      const updatedCampaign = await response.json();


      runInAction(() => {
        const index = this.campaigns.findIndex(c => c.marketingCampaignId === campaignId);
        if (index !== -1) {
          this.campaigns[index] = updatedCampaign;
        }
        this.loading = false;
      });

      return updatedCampaign;
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
      });
      throw error;
    }
  }

  // Delete campaign
  async deleteCampaign(campaignId) {
    this.loading = true;
    this.error = null;

    try {
      await shop.fetch(`/api/campaigns/${campaignId}`, {
        method: 'DELETE',
      });

      runInAction(() => {
        this.campaigns = this.campaigns.filter(
          c => c.marketingCampaignId !== campaignId
        );
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
      });
      throw error;
    }
  }


  async deactivateCustomerList(campaignId, vehicleCustomerIds) {
    this.loading = true;
    this.error = null;

    try {
      const response = await shop.fetch(`/v1/customer/hash/campaign/${campaignId}/vehicles/remove`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          VehicleCustomerIds: vehicleCustomerIds
        }),
      });

      const deactivatedCampaign = await response.json();

      this.loading = false;
      console.log({ deactivatedCampaign });
      return deactivatedCampaign;
    } catch (error) {
      throw error;
    }
  }

  async activateCustomerList(campaignId, vehicleCustomerIds) {
    this.loading = true;
    this.error = null;

    try {
      const response = await shop.fetch(`/v1/customer/hash/campaign/${campaignId}/vehicles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          VehicleCustomerIds: vehicleCustomerIds
        }),
      });

      const activatedCampaign = await response.json();
      this.loading = false;

      console.log({ activatedCampaign });
      return activatedCampaign;
    } catch (error) {
      throw error;
    }
  }

  // Activate campaign
  async activateCampaign(campaignId) {
    this.loading = true;
    this.error = null;

    try {
      const response = await shop.fetch(`/v1/customer/hash/campaign/${campaignId}/activate`, {
        method: 'POST'
      });
      const activatedCampaign = await response.json();
      const oldCampaign = this.campaigns.find(c => c.marketingCampaignId === campaignId);

      const campaigns = [...this.campaigns]

      runInAction(() => {
        const index = this.campaigns.findIndex(c => c.marketingCampaignId === campaignId);
        if (index !== -1) {
          oldCampaign.active = true;
          campaigns[index] = oldCampaign;
        }
        this.campaigns = campaigns;
        this.loading = false;
      });

      return activatedCampaign;
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
      });
      throw error;
    }
  }

  // Deactivate campaign
  async deactivateCampaign(campaignId) {
    this.loading = true;
    this.error = null;

    const campaigns = [...this.campaigns]

    try {
      const response = await shop.fetch(`/v1/customer/hash/campaign/${campaignId}/deactivate`, {
        method: 'POST'
      });
      const deactivatedCampaign = await response.json();
      const oldCampaign = this.campaigns.find(c => c.marketingCampaignId === campaignId);

      runInAction(() => {
        const index = this.campaigns.findIndex(c => c.marketingCampaignId === campaignId);
        if (index !== -1) {
          oldCampaign.active = false;
          campaigns[index] = oldCampaign;
        }
        this.campaigns = campaigns;
        this.loading = false;
      });

      return deactivatedCampaign;
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
      });
      throw error;
    }
  }


  // Get single campaign
  async fetchCampaign(campaignId) {
    this.loading = true;
    this.error = null;

    try {
      const response = await shop.fetch(`/v1/customer/hash/campaign/${campaignId}`);
      const campaign = await response.json();

      runInAction(() => {
        const index = this.campaigns.findIndex(c => c.marketingCampaignId === campaignId);
        if (index !== -1) {
          this.campaigns[index] = campaign;
        } else {
          this.campaigns.push(campaign);
        }
        this.loading = false;
      });

      return campaign;
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
      });
      throw error;
    }
  }
}