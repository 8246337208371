import { observer } from "mobx-react"
import React, { useState } from "react"
import { Button, Table, TextArea } from "semantic-ui-react"
// import cartStore from "store/cartStore"
import currency from "utils/currency"
import "./CartPreview.css"
import ImageWithFallback from "component/ImageWithFallback"
import Icon from "../../utils/Icon"
import { faMinus, faPlus, faTrash } from "@fortawesome/pro-light-svg-icons"
import { priceSignalDescriptions } from "../Price/PriceSignalContainer"
import checkoutStore from "store/checkoutStore"


const ProductCount = ({ product, onChange }) => {
  const [amount, setAmount] = React.useState(product.quantity)

  const updatePosition = (count) => {
    cartStore.addPosition(product, count)
    onChange(count)
  }

  return <div className="flex flex-row items-center">
    <div>
      <Button
        basic
        size={"tiny"}
        onClick={() => {
          const count = Math.max(0, amount - 1) || 1
          setAmount(count)
          updatePosition(count)
        }}
        icon={<Icon icon={faMinus} />} />

    </div>
    <div className="pa3 f6 tc mr1">{amount}</div>
    <Button
      basic
      size={"tiny"}
      onClick={() => {
        const count = Math.min(amount + 1, (product.maxCount || Infinity)) || 1
        setAmount(count)
        updatePosition(count)

      }}
      icon={<Icon icon={faPlus} />} />
    <div>


    </div>
  </div>
}


export const productTypeNames = {
  "PRD": "Werkstatt",
  "SRV": "Service",
  "TYR": "Reifen",
  "WPK": "Komplettrad",
  "PRT": "Ersatzteil",
}


function ProductRow({ product }) {
  const [quantity, setCount] = useState(product.quantity)
  return <div className="ProductRow ph3 pv2 pv1-ns ph0-ns bb b--black-10">
    <div className="flex flex-row items-center w-100">
      <div className={"pr3 dn db-ns"}>
        <ProductCount onChange={(quantity) => {
          setCount(quantity)
        }
        } product={product} />
      </div>
      <div className="flex row w-100 items-center">
        <div className="w-100">
          <div className="flex flex-row w-100 ">
            <div>
              <div className="f7 b black-60">{productTypeNames[product.type]}</div>
              <div>{product.name}</div>
            </div>
            <div className="ml-auto pl3 db dn-ns">{currency(product.price)}</div>
          </div>
          <div className="db dn-ns flex flex-row  items-center">
            <ProductCount product={product} onChange={(quantity) => {
              setCount(quantity)
            }
            } />
            <div className="ml-auto">
              <Button
                onClick={() => {
                  cartStore.removePosition(product)
                }}
                basic size="tiny" icon={<Icon icon={faTrash} />} />
            </div>
          </div>
        </div>
        {/*<div className="ml-auto dn db-ns nowrap">{currency( product.price, 2, "noch offen" )}</div>*/}
        <div className="ml-auto dn db-ns nowrap">{currency(product.price * product.quantity, 2, "noch offen")}</div>
      </div>
      <div className="pl3 dn db-ns">
        <Button
          onClick={() => {
            cartStore.removePosition(product)
          }}
          basic size="tiny" icon={<Icon icon={faTrash} />} />
      </div>
    </div>
    {product.associatedProducts.length > 0 && <div className="pt2 ">
      {product.associatedProducts.map(associatedProduct => {
        return <div key={associatedProduct.productID}>
          <div className="flex flex-row items-center pb2">
            <div className="black-70 ttu f7 black-60 outline-border ph2 pv mr2 dn db-ns">{associatedProduct.category || "Zusatzprodukt"}</div>
            <div>{associatedProduct.quantity > 1 ? associatedProduct.quantity : ""} {associatedProduct.name}</div>
            <div className="pl3 ml-auto"> {currency(associatedProduct.price * product.quantity, 2, "noch offen")}</div>
            {/*<div> {associatedProduct.priceFlag}</div>*/}
            <div className="pl2">
              <Button
                onClick={() => {
                  cartStore.toggleAssociatedProduct(product, associatedProduct, 0)
                }}
                size="tiny" basic icon={<Icon icon={faTrash} />} />
            </div>
          </div>
        </div>
      })}
    </div>}
  </div>
}

class CartPreview extends React.Component {

  render() {
    const { products, status } = cartStore
    const primaryProduct = products[0]
    if (!primaryProduct) return null
    const { associatedProducts } = primaryProduct
    return (
      <div style={{ paddingBottom: 0 }}>
        <div className="">
          {products.map((product, index) => {
            return <div key={product.productID}>
              <ProductRow product={product} />
            </div>
          })}

          {/*<Segment.Group>*/}
          {/*    {associatedProducts.map( product => {*/}
          {/*        if ( product.quantity === 0 ) return null*/}
          {/*        return (<Segment key={product.productID}>*/}
          {/*            <div className="black-70 ttu f6">{product.category}</div>*/}
          {/*            <div className="fr">{currency( product.price )}</div>*/}
          {/*            <div>{product.category === "Kompletträder" && product.quantity} {product.name}</div>*/}
          {/*        </Segment>)*/}
          {/*    } )}*/}
          {/*</Segment.Group>*/}
        </div>
      </div>
    )
  }
}

export default observer(CartPreview);


export class CartPreviewTable extends React.Component {

  render() {
    const { delivery = true, installation = false, readOnly } = this.props
    const { products } = cartStore
    const comment = checkoutStore.data?.formData?.comment

    return <div>

      <Table basic="very" className="CartPreviewTable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Artikel</Table.HeaderCell>
            <Table.HeaderCell>Einzelpreis</Table.HeaderCell>
            <Table.HeaderCell>Anzahl</Table.HeaderCell>
            <Table.HeaderCell>Summe</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {products.map(product => {

            let quantity = product.quantity
            let price = product.priceGrossInEur
            let retailPrice = product.recommendedRetailPriceGrossInEur
            let discount = retailPrice - price
            let sum = quantity * price
            let retailSum = quantity * retailPrice
            // WPK are quantity = 1
            if (product.type === "WPK") {
              quantity = quantity * 4
              price = price / 4
              retailPrice = retailPrice / 4
            }

            const primaryImage = product.images && product.images[0]
            return <Table.Row key={product.productID} className="ProductRow" verticalAlign={"top"}>
              <Table.Cell>
                <div className="flex flex-row items-center">
                  {primaryImage && <div>
                    <ImageWithFallback fallbackSrc={null} width={50} src={primaryImage} className={"pr3"} />
                  </div>
                  }
                  <div>
                    <div className="b mw6">{product.name}</div>
                    <div className="f6 black-50">{product.rimDesign}</div>
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="black-50 nowrap">{currency(retailPrice)}</div>
              </Table.Cell>
              <Table.Cell>
                <div className="black-50">{quantity}</div>
              </Table.Cell>
              <Table.Cell textAlign="right"><span className="black nowrap">{currency(retailSum)}</span></Table.Cell>
            </Table.Row>
          })}


          <CartTableExtras readOnly delivery={delivery} installation={installation} />

        </Table.Body>
      </Table>


    </div>
  }
}


export class CartTableExtras extends React.Component {

  render() {

    const { primaryPriceFlag, hasUndeterminedPriceFlag } = cartStore
    const { delivery, installation, children, readOnly } = this.props


    return <React.Fragment>

      {children}

      {/*/!* RABATT *!/*/}
      {/*{cartStore.totalDiscount > 0 && <Table.Row verticalAlign={"top"} className={"skinny"}>*/}
      {/*    <Table.Cell colSpan={3}>*/}
      {/*        <div className="flex flex-row">*/}
      {/*            <div>*/}
      {/*                <div className=" mw6 red">Rabatt</div>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </Table.Cell>*/}
      {/*    <Table.Cell textAlign="right"><span className="red tr ">-{currency( cartStore.totalDiscount )}</span></Table.Cell>*/}
      {/*</Table.Row>}*/}

      {/* ZWISCHENSUMME */}
      {/*<Table.Row verticalAlign={"top"} className={"skinny"}>*/}
      {/*    <Table.Cell colSpan={3}>*/}
      {/*        <div className="flex flex-row pb3">*/}
      {/*            <div className="black-70 ">Zwischensumme</div>*/}
      {/*        </div>*/}
      {/*    </Table.Cell>*/}
      {/*    <Table.Cell textAlign="right"><span className="black-70 tr">{currency( cartStore.total )}</span></Table.Cell>*/}
      {/*</Table.Row>*/}

      {/*/!* LIEFERUNG INKL. *!/*/}
      {/*{delivery && <Table.Row verticalAlign={"top"} className={"skinny"}>*/}
      {/*    <Table.Cell colSpan={3}>*/}
      {/*        <div className="flex flex-row">*/}
      {/*            <div className="black-70 ">Lieferung inklusive</div>*/}
      {/*        </div>*/}
      {/*    </Table.Cell>*/}
      {/*    <Table.Cell textAlign="right"><span className="black-70 tr">0,00 €</span></Table.Cell>*/}
      {/*</Table.Row>}*/}

      {/*/!* MOntage INKL. *!/*/}
      {/*{installation && <Table.Row verticalAlign={"top"} className={"skinny"}>*/}
      {/*    <Table.Cell colSpan={3}>*/}
      {/*        <div className="flex flex-row">*/}
      {/*            <div className="black-70 ">Montage inklusive</div>*/}
      {/*        </div>*/}
      {/*    </Table.Cell>*/}
      {/*    <Table.Cell textAlign="right"><span className="black-70 tr">0,00 €</span></Table.Cell>*/}
      {/*</Table.Row>}*/}


      {/*{primaryProduct.associatedProducts && primaryProduct.associatedProducts.map( addon => {*/}
      {/*    return <Table.Row verticalAlign={"top"} className={"skinny"} key={addon.productID}>*/}
      {/*        <Table.Cell colSpan={3}>*/}
      {/*            <div className="flex flex-row">*/}
      {/*                <div className="black-70 " title={addon.name}>{addon.name.slice( 0, 30 )} {addon.name.length > 30 ? "..." : ""}</div>*/}
      {/*            </div>*/}
      {/*        </Table.Cell>*/}
      {/*        <Table.Cell textAlign="right"><span className="black-70 tr">{currency( addon.price )}</span></Table.Cell>*/}
      {/*    </Table.Row>*/}
      {/*} )}*/}

      {/*/!* VISUAL PADDING D: *!/*/}
      {/*<Table.Row verticalAlign={"top"} className={"skinny extra-skinny"}>*/}
      {/*    <Table.Cell colSpan={4}>&nbsp;</Table.Cell>*/}
      {/*</Table.Row>*/}



      {/* TOTAL */}
      <Table.Row verticalAlign={"top"} className="lh-title">
        <Table.Cell colSpan={3}>
          <div className="b ">Gesamtsumme</div>
        </Table.Cell>

        <Table.Cell textAlign="right">
          <span className="b nowrap">{currency(cartStore.total)}
            {primaryPriceFlag && <span>
              {primaryPriceFlag.priceSignal !== 3 && <sup>*</sup>}
            </span>}
          </span>
          <div className="black-50 f7">inkl. MwSt.</div>
        </Table.Cell>
      </Table.Row>




      {(primaryPriceFlag && primaryPriceFlag.priceSignal !== 3) && <Table.Row>
        <Table.Cell colSpan={4}>
          <div className="pre-line f7 black-70">
            <p>*{priceSignalDescriptions[primaryPriceFlag.priceSignal]}</p>
            {/*{(hasUndeterminedPriceFlag && primaryPriceFlag.priceSignal !== 5) && <p>* Sie haben eine Werkstattleistung gewählt, deren Kosten "noch offen" sind. Ein Servicemitarbeiter wird sich Ihr Fahrzeug ansehen und dann mit einer genaueren Kostenschätzung auf Sie zukommen.</p>}*/}
            {/*<p>* Wir haben für Sie den Festpreis mit Ersatzteilen von "der Marke Ihres Vertrauens" auf Basis Ihrer Fahrzeugeingabe zusammengestellt.</p>*/}
          </div>
        </Table.Cell>
      </Table.Row>}





    </React.Fragment>
  }
}


CartPreview.Table = CartPreviewTable
