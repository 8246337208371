import { action, observable, makeObservable } from "mobx"

import config from "store/store.config"
import store from "store"
import _ from "lodash-es"
import Order from "model/Order"


class Cart {
  products = []
  shoppingCartID = false
  vehicleReferenceId = ""
  data = {}
  isAnonym = false

  constructor() {
    makeObservable(this, {
      products: observable,
      shoppingCartID: observable,
      vehicleReferenceId: observable,
      data: observable,
      isAnonym: observable,
      fetch: action,
      save: action,
      add: action,
      reset: action,
      put: action,
      post: action
    })
  }

  hydrate() {
    this.shoppingCartID = store.get("shoppingCartID")
    this.fetch()
    // // --> console.log( "hydrated cart with", this.shoppingCartID )
  }

  async fetch() {
    let isAnonym = !sessionStore.profile
    let anonymous = isAnonym ? "/anonymous" : ""
    if (this.shoppingCartID && this.shoppingCartID !== "undefined") {
      console.log("FETCH CART", this.shoppingCartID)
      return fetch(`${config.API_BASE}/shopping-cart${anonymous}/${this.shoppingCartID}`, {
        "credentials": "include",
        "headers": {
          "Authorization": sessionStore.authorization,
          "IdentityUserId": sessionStore.identityUserId
        },
        "method": "GET",
        "mode": "cors"
      }).then(response => {
        if (response.status >= 400 && response.status <= 500) {
          console.warn("Cart didn't load. Code: ", response, response.status)
        }
        return response
      })
        .then(res => res.json())
        .then(this.handleCartResponse)
        .catch(this.handleError)
    }
  }

  get hasShoppingCartID() {
    return this.shoppingCartID && this.shoppingCartID !== "undefined"
  }

  save(cart) {
    if (!cart) throw Error("Invalid Cart for cart.save(cart)")
    if (this.promise) {
      return this.promise
    }
    // --> console.log(this.shoppingCartID, {cart})

    if (this.hasShoppingCartID) return this.put(cart)
    if (!this.hasShoppingCartID) return this.post(cart)
  }

  add(product) {
    this.products.set(product.productID, product)
  }

  reset() {
    this.products = new Map()
    this.shoppingCartID = false
    paymentStore.reset()
    locationStore.removeItem("shoppingCartID")
  }

  put(cart) {
    let isAnonym = cart.isAnonym ? "anonymous/" : ""

    let { shoppingCartID } = this
    let order = this.makeOrder(cart)
    this.promise = fetch(`${config.API_BASE}/shopping-cart/${isAnonym}${shoppingCartID}`, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify(order),
      headers: {
        "Content-Type": "application/json",
        "Authorization": sessionStore.authorization,
        "IdentityUserId": sessionStore.identityUserId
      }
    })
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          console.warn("CART ERROR: CREATING NEW CART", {
            response,
            cart
          })
          return this.post(cart)
        }
        return response
      })
      .then(res => {
        if (res.json) return res.json()
        return res
      })
      .then(this.handleCartResponse)
      .catch(this.handleError)

    return this.promise
  }

  post(cart) {
    //TODO: CART CAN BE LESS DATA THAN ORDER
    let order = this.makeOrder(cart)
    let isAnonym = cart.isAnonym ? "/anonymous" : ""
    console.log("POST ORDER", { order })

    this.promise = fetch(`${config.API_BASE}/shopping-cart${isAnonym}`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(order),
      headers: {
        "Content-Type": "application/json",
        "Authorization": sessionStore.authorization,
        "IdentityUserId": sessionStore.identityUserId
      }
    })
      .then(res => res.json())
      .then(this.handleCartResponse)
      .catch(this.handleError)

    return this.promise
  }

  handleError = (err) => {
    console.warn("Cart Error: ", err)
    this.promise = false
    this.reset()
  }

  handleCartResponse = (data) => {
    this.data = data
    this.promise = false
    this.shoppingCartID = data.shoppingCartID
    this.shoppingCartID && locationStore.setItem("shoppingCartID", this.shoppingCartID)

    //TODO: MERGE DATA FROM API INTO cartSTore.products
    console.log("handleCartResponse", { data })
    // --> console.log( "shoppingCart", data )
    return data
  }

  makeOrder = (data) => {
    const {
      activeVehicle,
      activeVehicleProfile
    } = locationStore
    const { formData = {} } = checkoutStore.data || {}

    let {
      products,
      billingAddress,
      deliveryAddress = null
    } = data

    if (!billingAddress) {
      billingAddress = sessionStore.profile && sessionStore.profile.billingAddress
    }

    // get any VIN
    let VIN = _.first(data.products
      .map(p => p.VIN)
      .filter(p => !!p))
    const isPrimaryAtStore = locationStore.isActiveStorePrimaryBrand(locationStore.activeManufacturerKey)

    let manufacturer = activeVehicle ? activeVehicle.manufacturerName : locationStore.activeManufacturerKey
    if (!manufacturer) {
      const manufacturerObject = criteriaStore.guessManufacturer(activeVehicleProfile.manufacturer)
      if (manufacturerObject) manufacturer = manufacturerObject.id
    }

    let order = {
      "store": data.store, // "automaker"      : data.automaker,
      // "manufacturer"   : data.manufacturer || locationStore.activeManufacturerKey,
      "billingAddress": billingAddress,
      "deliveryAddress": deliveryAddress,
      "phoneNumber": formData.phone,
      "discountCodes": data.discountCodes || [],
      "customerNumber": checkoutStore.data.internalCustomerNumber,
      "message": formData.message || formData.comment,
      "vehicle": {
        manufacturer: isPrimaryAtStore ? manufacturer : "NACARMOS",
        manufacturerKeyNumber: activeVehicle?.hsn,
        versionKeyNumber: activeVehicle?.vsn,
        typeKeyNumber: activeVehicle?.tsn,
        mileage: activeVehicleProfile.mileage,
        firstRegistration: activeVehicleProfile.firstRegistration ? activeVehicleProfile.firstRegistration : null,
        vehicleReferenceId: activeVehicle?.referenceId,
        VehicleIdentificationNumber: VIN,
        licensePlate: formData.carId
      },
      "products": products.map((product) => {
        const productID = product.productIDAddon ? `${product.productID}-${product.productIDAddon}` : `${product.productID}`
        product.associatedProducts = product.associatedProducts || []
        return {
          productID: productID,
          quantity: Number(product.quantity),
          metafield: product.metafield || {},
          vehicleReferenceId: product.vehicleReferenceId,
          associatedProducts: product.associatedProducts
            .filter(product => product.quantity > 0)
            .map(associatedProduct => {
              return {
                productID: associatedProduct.productID,
                quantity: associatedProduct.quantity,
                metafield: associatedProduct.metafield || {}
              }
            })
        }
      })
    }

    // CLEAN NULL VALUES
    Object.keys(order).forEach((k) => order[k] == null && delete order[k]);

    const invoiceMessage = cartStore.allowInvoiceEmail ? "\n\n-- Rechnung per E-Mail" : ""
    order.message = `${order.message}${invoiceMessage}`
    return new Order(order).data
  }
}


const cart = new Cart()
export default cart
