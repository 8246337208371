import { UserManager, WebStorageStateStore } from "oidc-client-ts"
import { action, computed, makeObservable, observable } from "mobx"
const oidcConfig = {
  authority: "https://securitydev2.auto-nagel-cloud.de",
  client_id: "ncg-frontend-client",
  redirect_uri: window.location.origin + "/shop/auth/sign-in",
  post_logout_redirect_uri: window.location.origin + `/shop/auth/sign-out`,
  scope: "openid profile roles",
  grant_type: "authorization_code",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
}

export default class AuthStore {
  user = null

  constructor() {
    this.userManager = new UserManager(oidcConfig)
    this.userManager.events.addUserLoaded((user) => {
      console.log("User loaded:", user)
    })
    this.userManager.events.addUserUnloaded((user) => {
      console.log("User unloaded:", user)
    })

    this.userManager.events.addAccessTokenExpired((user) => {
      console.log("Token expired:", user)
      this.logout()
    })

    this.userManager.events.addUserSignedOut((user) => {
      console.log("User signed out:", user)
    })
    this.userManager.events.addUserSignedIn((user) => {
      console.log("User signed in:", user)
      sessionStorage.setItem("user", JSON.stringify(user))
    })

    const user = sessionStorage.getItem("user")
    if (user) {
      this.setUser(JSON.parse(user))
    }

    makeObservable(this, {
      user: observable,
      handleCallback: action,
      renewToken: action,
      logout: action,
    })
  }

  async login() {
    try {
      await this.userManager.signinRedirect()
    } catch (error) {
      console.error("Login error:", error)
    }
  }

  setUser(user) {
    this.user = user
    sessionStorage.setItem("user", JSON.stringify(user))
    // calculate expiry
    const expiry = new Date(user.expires_at * 1000)
    this.user.expiry = expiry
    this.user.isExpired = expiry < new Date()
  }

  async signOut() {
    await this.userManager.signOut({
      post_logout_redirect_uri: window.location.origin + `/shop/auth/sign-out`,
    })
  }

  async handleCallback() {
    try {
      const user = await this.userManager.signinRedirectCallback()
      this.setUser(user)
      console.log("User logged in with callback:", user)
    } catch (error) {
      this.user = null
      sessionStorage.removeItem("user")
      console.error("Callback error:", error)
    }
  }

  async isAuthenticated() {
    const user = await this.getUser()
    return user !== null && !user.expired
  }

  async getAccessToken() {
    const user = await this.getUser()
    return user.access_token
  }

  async renewToken() {
    return await this.userManager.signinSilent()
  }

  // Enhanced logout with fallback
  async logout() {
    try {
      // Check if end_session_endpoint is available
      const metadata = await this.userManager.metadataService.getMetadata()
      if (metadata.end_session_endpoint) {
        console.log("Using OIDC provider logout endpoint:", metadata.end_session_endpoint)
        await this.userManager.signoutRedirect({
          post_logout_redirect_uri: "http://localhost:3000/shop/auth/sign-out", // Explicitly pass redirect URI
        })
      } else {
        console.warn("No end_session_endpoint available. Performing local logout.")
        await this.localLogout()
      }
    } catch (error) {
      console.error("Logout error:", error)
      // Fallback to local logout if signoutRedirect fails
      await this.localLogout()
    }
  }

  // Local logout fallback
  async localLogout() {
    await this.userManager.removeUser() // Clear user data from storage
    this.user = null
    sessionStorage.removeItem("user")
    window.location.href = "http://localhost:3000/shop/auth/sign-out-manually" // Manual redirect
  }
  async getUser() {
    try {
      const user = await this.userManager.getUser()
      this.setUser(user)
      return user
    } catch (error) {
      console.error("Get user error:", error)
      return null
    }
  }

  async renewToken() {
    try {
      await this.userManager.signinSilent()
    } catch (error) {
      console.error("Token renewal error:", error)
    }
  }

  async loginWithCode(code) {
    try {
      const user = await this.userManager.signinRedirectCallback(code)
      console.log("User logged in with code:", user)
      return user
    } catch (error) {
      console.error("Login with code error:", error)
      return null
    }
  }

  async loginWithCredentials(username, password) {
    try {
      const user = await this.userManager.signinResourceOwnerCredentials({
        username,
        password,
        scope: "openid profile roles",
      })
      console.log("User logged in with credentials:", user)
      return user
    } catch (error) {
      console.error("Login with credentials error:", error)
      return null
    }
  }

  async hydrateUser() {
    try {
      const user = await this.userManager.getUser()
      if (user) {
        this.user = user
        console.log("User hydrated:", this.user)
      } else {
        console.log("No user data available to hydrate.")
      }
    } catch (error) {
      console.error("Error hydrating user:", error)
    }
  }
}
