import { CartTableExtras } from "component/Cart/CartPreview"
import { observer } from "mobx-react"
import React, { useState } from "react"
import Icon from "utils/Icon"
import { Button, Divider, Form, Image, Input, Modal, Table } from "semantic-ui-react"
// import cartStore from "store/cartStore"
import currency from "utils/currency"
import "./CartPreview.css"
import { Link } from "react-router-dom"
// import locationStore from "store/locationStore"
import VehicleCardMinimal from "../Vehicle/VehicleCardMinimal"
import { faTrash } from "@fortawesome/pro-light-svg-icons"
import { QuickOrderSearch } from "page/StorePage/QuickorderInput"
import { ArrowButton } from "../Button/ArrowButton"
import { sidebarStates } from "../../store/uiStore"
import { TextArea } from "semantic-ui-react"

const getLowestPriceFlag = (product) => {
  const priceFlagsOrder = ["workprice", "workprice+", "variantprice", "fixedprice", "specialprice"]
  const associatedProducts = product.associatedProducts || []
  const lowestPriceFlag = associatedProducts.reduce((lowestFlagYet, { priceFlag }, n) => {
    const i = priceFlagsOrder.indexOf(lowestFlagYet)
    const j = priceFlagsOrder.indexOf(priceFlag)
    return i < j ? lowestFlagYet : priceFlag
  }, product.priceFlag)
  return lowestPriceFlag
}
const getpriceFlagDescription = (product) => {
  const lowestPriceFlag = getLowestPriceFlag(product)
  if (product.type === "SRV") return false

  let manufacturerName
  try {
    manufacturerName = product.partGroups[0].parts[0].manufacturer
  } catch (err) {
    manufacturerName = "der Marke Ihres Vertrauens"
  }
  switch (lowestPriceFlag) {
    case "fixedprice":
    case "specialprice":
      return `Wir haben für Sie den Festpreis mit Ersatzteilen von “${manufacturerName}” auf Basis Ihrer Fahrzeugeingabe zusammengestellt. `
    // return `Wir haben für Sie den Festpreis für die Reparatur “${product.itemMountPosition}” mit Ersatzteilen
    // von “${manufacturerName}” auf Basis Ihrer Fahrzeugeingabe zusammengestellt. `
    case "variantprice":
      return `Der aktuelle Preis zeigt eine Variante mit Ersatzteilen von ${manufacturerName}.`
    // return `Bei der Ermittlung des Festpreises für die Reparatur “${product.itemMountPosition}” haben wir
    // unterschiedliche Ausstattungsvarianten für Ihr Fahrzeug gefunden. Der aktuelle Preis zeigt eine Variante mit
    // Ersatzteilen von ${manufacturerName}.`
    case "workprice":
    case "workprice+":
      return `Es kommen noch Materialkosten hinzu, für die keine genauen Angaben vorliegen.`
    // return `Bei der Ermittlung des Festpreises für die Reparatur “${product.itemMountPosition}” haben wir nicht
    // alle Ersatzteile ermitteln können. Der Preis ist daher noch unvollständig.`
    default:
      return null
  }
}

const getProductName = ({ product }) => {
  const priceFlagDescription = false

  const descriptionHasMoreData = (product.descriptionTitle || product.description) != product.name
  switch (product.type) {
    case "TYR":
      return <React.Fragment>
        <div className="b">Reifen</div>
        <div className="mw6">{product.quantity}x {product.name} </div>
      </React.Fragment>
    case "WPK":
      return <React.Fragment>
        <div className="b">Komplettrad</div>
        <div className="mw6">4x {product.tyreManufacturer} {product.tyreModelName} </div>
        <div
          className="f7 black-60 line-clamp-3 lh-title">{product.rimManufacturer} {product.rimDesign} {product.rimDiameter}"
        </div>

        {product.hasTyrePressureSensor && <div className="f6 black-60 tracked-narrow line-clamp-3 lh-title">inkl. RDKS</div>}
      </React.Fragment>

    case "SRV":
      return <div>
        <div className="b">{product.mainCategoryName || product.labourTimesGroup}</div>
        {/* <pre>{product.lowestPriceFlag}</pre> */}
        {priceFlagDescription && <div>{priceFlagDescription}</div>}
        {product.name && <div className="f6 black-60 tracked-narrow line-clamp-3 lh-title">{product.name}</div>}
        {/*{product.name && <div className="f7 black">{product.productID}</div>}*/}
      </div>
    case "PRD":
      return <div>
        <div className="mw6 b">{product.name}</div>
        {/*<pre>{product.lowestPriceFlag}</pre>*/}
        {priceFlagDescription && <div>{priceFlagDescription}</div>}
        {(product.description && descriptionHasMoreData) && <div
          className="f6 black-60 tracked-narrow line-clamp-3 lh-title pv1">{product.descriptionTitle || product.description}</div>}
      </div>
    case "PRT":
      return <div>
        <div className="mw6 b">{product.quantity}x {product.name}</div>
        {product.description && <div className="f6 black-60 tracked-narrow line-clamp-3 lh-title pv1">{product.description}</div>}
      </div>
    default:
      return <div className="mw6">{product.name}</div>
  }
}
export { getProductName }


export const CartLinks = observer(function (props) {
  const [hasCoupon, setCoupon] = useState(false)
  const [couponCode, setCouponCode] = useState(false)
  const { isOnlineStore, activeVehicleProfile } = locationStore
  const hasService = cartStore.hasProductType("SRV")
  const discountCodes = cartStore.discountCodes.filter(e => e)
  const {
    showContinueShopping = false,
    toCart = false,
    showCouponInput = true,
    onClick = null,
  } = props
  return <div key={"cart-prv-cta"} className="pt3">

    {discountCodes.length > 0 && <div className={"f6 pa2 bg-silver mb3"}>
      <div className="b">Aktive Coupons</div>
      {cartStore.discountCodes.map((code, index) => {
        return <div key={index}>{code}</div>
      })}
    </div>}


    {(!activeVehicleProfile?.isLocal) && <div className="pb-2">
      <ArrowButton basic
        onClick={() => {
          uiStore.setSidebarState(sidebarStates.HIDDEN)
        }}
        as={Link} to={`${activeVehicleProfile.url}`}>Fahrzeug anzeigen</ArrowButton>
    </div>}

    {cartStore.primaryProduct && <React.Fragment>
      <div className="pb2">


        {!toCart && <>
          {isOnlineStore && <Button as={Link}
            to={`?showStoreFinder=true&referrer=${locationStore.getPath("/checkout/")}`}
            primary
            onClick={onClick}
            fluid>Buchen & Termin</Button>}

          {!isOnlineStore && <Button as={Link}
            to={locationStore.getPath("/checkout/")}
            primary
            onClick={onClick}
            fluid>Buchen & Termin</Button>}
        </>}


        {toCart && <div className="pb-2">
          {isOnlineStore && <ArrowButton as={Link}
            to={`?showStoreFinder=true&referrer=${locationStore.getPath("/cart/")}`}
            primary
            onClick={onClick}
            basic
            fluid>Warenkorb bearbeiten</ArrowButton>}

          {!isOnlineStore && <ArrowButton as={Link}

            to={locationStore.getPath("/cart/")}
            primary
            onClick={onClick}
            basic
            fluid>Warenkorb bearbeiten</ArrowButton>}
        </div>}

        {toCart && <>
          {isOnlineStore && <ArrowButton as={Link}

            to={`?showStoreFinder=true&referrer=${locationStore.getPath("/checkout/")}`}
            primary
            onClick={onClick}
            basic={false}
            fluid>Buchen & Termin</ArrowButton>}

          {!isOnlineStore && <ArrowButton as={Link}
            to={locationStore.getPath("/checkout/")}
            primary
            onClick={onClick}
            basic={false}
            size={"medium"}
            fluid>Buchen & Termin</ArrowButton>}
        </>}
      </div>
    </React.Fragment>}
    {showContinueShopping && <Button as={Link}
      to={locationStore.getPath("/")}
      basic
      fluid>Weiter einkaufen</Button>}

    {(!hasCoupon && showCouponInput) && <div className="pt3">
      <Button basic size={"tiny"} fluid onClick={() => {
        setCoupon(true)
      }}>Ich habe einen Coupon</Button>
    </div>}

    {hasCoupon && <div className={"coupon"}>
      <div className={"animate fadeInDown"}>
        <Form>
          <label htmlFor="coupon">Coupon</label>
          <Input fluid size={"mini"} onChange={(e) => {
            setCouponCode({ couponCode: e.currentTarget.value })
          }} />
          <div className="pt1">
            <Button basic size={"tiny"} fluid onClick={() => {
              cartStore.activateDiscountCode(couponCode)
            }}>Aktivieren</Button>
          </div>
        </Form>
      </div>
    </div>}
  </div>
})


export function CarPreview({
  vehicle,
  vehicleProfile,
  className = ""
}) {
  return <div className={`CarPreview ${className}`}>
    {vehicleProfile && <React.Fragment>
      <div className={"f6 ttu black-60"}>Ihr Fahrzeug</div>
      <div className="">
        <VehicleCardMinimal vehicleProfile={vehicleProfile} vehicle={vehicle} />
      </div>
    </React.Fragment>}
  </div>
}

export function CartPreviewSummary() {
  return <div className="CartPreviewSummary">
    <h3 className={"SectionHeading pt3 f6 b ttu pb3 black-60 tracked-tight "}>Warenkorb</h3>
    <div className="pt2"><CartPreviewMinimal toCart /></div>
  </div>
}

export function CartPreviewSummaryToCart() {
  const {
    activeVehicle,
    activeVehicleProfile
  } = locationStore
  return <div className="CartPreviewSummary">
    <div>CART LINKS</div>
    <CartPreviewMinimal showStandardTitle toCart />
    <CartLinks showContinueShopping={false} toCart />
    <CarPreview className={"pt3"} vehicle={activeVehicle} vehicleProfile={activeVehicleProfile} />
    {/*{cartStore.primaryProduct && <div className="pt3">*/}
    {/*    <Button as={Link} to={locationStore.getPath( "/checkout/" )} primary size={"small"} fluid>Buchen & Termin</Button>*/}
    {/*</div>}*/}
  </div>
}

export function CartPreviewMinimalTable(props) {
  const {
    readOnly,
    hideImage,
    showVinCheck = false,
    showWorkingPackages = false,
    hideSummary = false
  } = props
  const filter = props.filter || function () {
    return true
  }
  let products = cartStore.products.filter(filter)
  const { showUVP = false } = props
  const product = cartStore.primaryProduct
  const comment = checkoutStore.data?.formData?.comment
  if (!product) return null
  if (!products) return null

  const activeVIN = cartStore.getVIN()
  return (<div style={{ paddingBottom: 0 }}>
    {products && products.map((product, index) => {
      const isLast = index >= products.length - 1
      const { associatedProducts = [] } = product
      let showImage = !hideImage && (product.type !== "SRV" && index === 0)  // only show first
      // image -> //
      // index === 0 &&
      // product.type
      // !== "SRV"
      if (product.type === "PRT") showImage = false
      const imgSrc = product && product.images && product.images.length >= 1 && product.images[0]
      const supportsTransparency = false // imgSrc && imgSrc.includes( ".png" )

      const needsVinCheck = product.needsVinCheck || associatedProducts.filter(e => e.needsVinCheck).length > 0
      const lowestPriceFlag = getLowestPriceFlag(product)

      return <div key={product.productID} className={`CartPreviewMinimal pb2 cart-type-${product.type}`}>
        {showImage && <React.Fragment>
          {imgSrc && <div className={`pb3 ${supportsTransparency ? "bg-silver" : "bg-white pt3"} mb3`}>
            <Image alt={"Produktabbildung"} centered style={{
              maxHeight: "160px",
              maxWidth: "66.33%"
            }} src={imgSrc} alt="" />
          </div>}
        </React.Fragment>}

        {/*<pre>{JSON.stringify(cartStore.cart.data, null, 2)}</pre>*/}
        <div className="">

          <div className={"flex-1 flex flex-row justify-between items-start"}>
            <div className="flex-1 flex flex-row justify-start">
              {!readOnly && <div className={"pv1 pr2 "}>
                <Button basic size={"tiny"} onClick={() => {
                  cartStore.removePosition(product)
                }} icon={<Icon icon={faTrash} />} />

              </div>}

              <div className={"product-name f6 flex-1"}>
                <div className="fr">

                  {!showUVP && <div className="pl3 fr fw-600 black-70 nowrap tr">
                    <div className="">
                      {product.hasDiscount && <div>
                        <div className=" text-gray-500 strike text-xs tr">{currency(product.recommendedRetailPriceGrossInEur)}</div>
                      </div>}
                      <div className={`font-bold ${product.hasDiscount && "text-red-600"}`}>
                        {product.customPriceName ? product.customPriceName :
                          <span>{currency((product.price || product.priceGrossInEur) * product.quantity)}</span>}
                      </div>
                    </div>
                    <div className="i f6">
                      {lowestPriceFlag === "workprice" && <em>zzgl.&nbsp;Materialkosten</em>}
                      {lowestPriceFlag === "workprice+" && <em>zzgl.&nbsp;Materialkosten</em>}
                      {lowestPriceFlag === "variantprice" && <em>mit Varianten</em>}
                      {/* {lowestPriceFlag === "fixedprice" && <em>Festpreis </em>} */}
                      {/* {lowestPriceFlag === "specialprice" && <em>Angebot </em>} */}
                    </div>
                  </div>}
                  {showUVP && <div
                    className="pl3 fr fw-600 black-70 nowrap f6">{currency(product.recommendedRetailPriceGrossInEur * product.quantity)}</div>}
                </div>
                {getProductName({ product })}
                {product.metafield?.customerRemark && <div className="f6 pl2 bl b--black-20 lh-title mb2 mt1">
                  <div
                    className="f7 black-40 tracked-narrow i">{product.metafield.customerRemark}</div>
                </div>}
              </div>
            </div>


          </div>

          {(showVinCheck && needsVinCheck) && <div className={"pv1"}>
            <div className="show-when-active"><VinInput product={product} /></div>
            <div
              className="hide-when-active show-when-inactive pa2 bg-silver black-80 f6">VIN {activeVIN}</div>
          </div>}
        </div>

        <div className="associated-products-container pb-3">
          {associatedProducts
            .map(product => {
              const showCategory = product.category && product.category !== "Inspektion"
              if (!product || product.quantity === 0) return null
              return (<div className="f6 associated-products hide-child" key={product.productID}>
                <div>
                  {showCategory && <div className="dib ttu f7  pr2"> {product.category} </div>}
                  {!readOnly && <span>
                    {/*<a href="#" className={"f7 fw4 blue hover-blue-dark dib"} onClick={() => {*/}
                    {/*    cartStore.toggleAssociatedProduct( cartStore.primaryProduct, product )*/}
                    {/*}}>entfernen</a>*/}
                  </span>}
                </div>
                <div className="text-xs flex flex-row items-center">
                  <div className=""> {product.quantity}x {product.name}</div>
                  <div className="ml-auto pl-1">{currency(product.price * (product.quantity || 1))}</div>
                </div>
              </div>)
            })}
        </div>
      </div>

    })}

    {!hideSummary && <Table unstackable singleLine className="CartPreviewTable " style={{ marginTop: 0 }} basic={"very"}>
      <Table.Body>
        <CartTableExtras />
      </Table.Body>
    </Table>}



  </div>)
}

const CartPreviewMinimal = observer(function CartPreviewMinimal({
  readOnly,
  toCart = false,
  showStandardTitle,
  showUVP = false,
  hideImage,
  showVinCheck = false,
  showWorkingPackages = false,
  hideSummary = false
}) {
  const { products } = cartStore
  const activeVIN = cartStore.getVIN()
  const comment = checkoutStore.data?.comment
  const product = cartStore.primaryProduct
  if (!product || !products) {
    return <>
      {showStandardTitle && (<>
        <div className="lh-title pt3">
          <div className=" b">Warenkorb</div>
          <div className="f6">Ihr Warenkorb ist leer.</div>
        </div>
        <Divider />
      </>)}
      {/* <div className="f5 black-60 mb3">Ihr Warenkorb ist leer.</div> */}
      <div className="f7 mb3 black-40 i lh-copy">Mit der Reparaturwunsch können Sie Produkte finden und in den Warenkorb legen.</div>
      <QuickOrderSearch insetPlaceholder />
    </>
  }

  // const showImages = cartStore.products.length === 1 && ( product.type === "TYR" || product.type === "WPK" )
  return (<div style={{ paddingBottom: 0 }}>


    {showStandardTitle && (<>
      <div className="lh-title pt3">
        <div className=" b">Warenkorb</div>
        <div className="f6">{products.length} {products.length === 1 ? "Eintrag" : "Einträge"}</div>
      </div>
      <Divider />
    </>)}
    {products && products.map((product, index) => {
      const { associatedProducts = [] } = product
      const showImage = !hideImage && product.type !== "SRV" && index === 0 && product.type !== "PRT"// only show first image -> // index === 0 &&
      // product.type !== "SRV"
      const isWPK = product.type === "WPK"
      const imgSrc = product && product.images && product.images.length >= 1 && product.images[0]
      const supportsTransparency = isWPK // imgSrc && imgSrc.includes( ".png" )
      const priceFlagDescription = product.type !== "SRV" && getpriceFlagDescription(product)

      return <div key={product.productID} className={`CartPreviewMinimal2 pb2 cart-type-${product.type} mb2`}>
        {/*<div className="text-xs pb-2">{product.productID}</div>*/}
        {showImage && <React.Fragment>
          {imgSrc && <div className={`pb3 ${supportsTransparency ? "bg-silver" : "bg-white"} mb3`}>
            <Image alt={"Produktabbildung"} centered style={{
              maxHeight: "160px",
              maxWidth: "66.33%"
            }} src={imgSrc} alt="" />
          </div>}
        </React.Fragment>}


        <div className="lh-title">
          <div className="flex flex-col justify-end fr">
            {product.customPriceName && <div>
              <div className="pl3 fr fw-600 black-70 nowrap f6 b">{product.customPriceName}</div>
            </div>}
            {!product.customPriceName && <div>
              {!showUVP && <div
                className={`pl3 fr fw-600 nowrap text-sm b ${product.hasDiscount && "text-red-600"}`}> {currency((product.price || product.priceGrossInEur) * product.quantity)}</div>}
              {showUVP && <div
                className={`pl3 fr fw-600 nowrap text-sm b ${product.hasDiscount && "text-red-600"}`}>{currency(product.recommendedRetailPriceGrossInEur * product.quantity)}</div>}
            </div>}
            {product.hasDiscount && <div>
              <div className=" text-gray-500 strike text-xs tr"> {currency(product.recommendedRetailPriceGrossInEur)}</div>
            </div>}
          </div>
          <div className={"product-name f6 "}>
            {getProductName({ product })}
            {product.metafield?.customerRemark && <div className="f6 pl2 bl b--black-20 lh-title mb2 mt1">
              <div className="f7 black-40 tracked-narrow i">{product.metafield.customerRemark}</div>
            </div>}
            {product.cartDescription && <div title={product.cartDescription} className="truncate text-xs uppercase text-gray-600">{product.cartDescription}</div>}

          </div>
        </div>

        {(showVinCheck && product.needsVinCheck) && <div className={"pv1"}>
          <div className="show-when-active"><VinInput product={product} /></div>
          <div className="hide-when-active show-when-inactive pa2 bg-silver black-80 f7">VIN {activeVIN}</div>
        </div>}
        {/*{priceFlagDescription && <div className={"f7"}>{priceFlagDescription}</div>}*/}

        {associatedProducts
          .map(associatedProduct => {
            if (!associatedProduct || associatedProduct.quantity === 0) return null
            return (<div className="f6 associated-associatedProducts hide-child pl2 bl b--black-20 lh-title mb1"
              key={associatedProduct.productID}>
              {product.hasDiscount && <div>
                <div className=" text-gray-500 strike text-xs tr">{currency(product.recommendedRetailPriceGrossInEur)}</div>
              </div>}
              <div
                className="f7 fr black-70 b ml2">{currency(associatedProduct.price * (associatedProduct.quantity || 1))}</div>
              <div>
                {associatedProduct.category !== "Inspektion" && <div
                  className="dib b f7 pr2 black-70"> {associatedProduct.category || "Zusatzprodukt"} </div>}
              </div>
              <div
                className="f7 black-60 tracked-narrow"> {associatedProduct.quantity}x {associatedProduct.name}</div>
            </div>)
          })}

        {!readOnly && <button type="button"
          className={"f7 fw4 blue hover-blue-dark db pointer mt1 dim bg-transparent"}
          onClick={() => {
            cartStore.togglePosition(product)
          }}>Entfernen</button>}
      </div>
    })}

    <div className="mt3">
      <QuickOrderSearch insetPlaceholder />
    </div>

    {!hideSummary && <Table unstackable singleLine className="CartPreviewTable " style={{ marginTop: 0 }} basic={"very"}>
      <Table.Body>
        <CartTableExtras toCart={toCart} />
      </Table.Body>
    </Table>}


    {/* Add Comment Section */}
    <div>
      <TextArea
        rows={2}
        value={comment}
        onChange={(e) => checkoutStore.setComment(e.target.value)}
        placeholder="Ihre Anmerkung zur Bestellung..."
        className="w-100 bg-gray-100 rounded-lg text-sm p-3 border border-gray-200 focus:outline-none focus:border-gray-300 resize-none"
      />
    </div>
  </div>)
})

export default CartPreviewMinimal


const VinInput = observer(function ({ product }) {
  const {
    activeVIN,
    activeVehicleProfile
  } = locationStore
  const [error, setError] = React.useState(false)
  const [blurred, setBlur] = React.useState(false)

  React.useEffect(() => {
    let vin = activeVehicleProfile && activeVehicleProfile.vehicleIdentificationNumber || ""
    if (vin) {
      locationStore.setActiveVIN(vin)
    }
  }, [activeVehicleProfile])
  return <React.Fragment>
    <Input
      size={"mini"}
      label="VIN"
      required
      maxLength={17}
      name={`${product.productID}-VIN`}
      onBlur={() => {
        setBlur(true)
        console.log("BLUR")
      }}
      value={activeVIN}
      onChange={(e) => {
        setBlur(false)
        product.VIN = e.currentTarget.value
        product.validVIN = e.currentTarget.value.length >= 13
        setError(!product.validVIN)
        locationStore.setActiveVIN(e.currentTarget.value)
      }} />
    <p className={"f7 black-60 pv1"}>
      Wir benötigen Ihre Fahrgestellnummer aus Ihrem Fahrzeugschein damit einer unserer Experten die passende
      Leistung für Sie ermitteln kann. Wenn Sie
      schon einmal bei uns
      waren brauchen wir nur Ihr Kennzeichen, sonst die Fahrgestellnummer. Beides können Sie hier oder im Checkout
      hinzufügen.
    </p>
    {/*<Popup></Popup>*/}
    {/*<Icon icon={faInfoCircle}/>*/}
    {/*{(error && blurred) && <div className="f7 red">*/}
    {/*    Bitte hinterlegen Sie die VIN Ihres Fahrzeuges.*/}
    {/*</div>}*/}
  </React.Fragment>
})
