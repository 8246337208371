import axios from "axios"
import config from "./store.config"
// // import sessionStore from "./sessionStore";

export default class CustomerStore {

  static async activateCustomer(email, storeCustomerNumber, phoneNumber, name, storeKey = "NACARMOS") {
    try {
      return axios(`${config.URL_BASE}api/v1/customers/activation`,
        {
          headers: {
            "Authorization": sessionStore.authorization
          },
          method: "POST",
          data: {
            email,
            storeCustomerNumber,
            phoneNumber,
            name,
            storeKey
          }
        }).catch(error => {
          console.log({ error })
          return { error }
        })
    } catch (err) {
      return false
    }
  }

  static async searchCustomer(searchObject = {

    "email": "pboewe@gmail.com",
    "firstName": "",
    "lastName": "",
    "companyName": "",
    "licensePlate": "",
    "vehicleIdentificationNumber": "",
    NcgCustomerNumber: ""
  }) {
    return axios(`${config.URL_BASE}api/v1/customers/search`,
      {
        headers: {
          "Authorization": sessionStore.authorization
        },
        method: "POST",
        data: {
          email: searchObject.email || null,
          firstName: searchObject.firstName || null,
          lastName: searchObject.lastName || null,
          companyName: searchObject.companyName || null,
          lastOrCompanyName: searchObject.lastOrCompanyName || null,
          ncgCustomerNumber: searchObject.customerId || null
        }
      })
      .then(res => {
        if (res.status !== 200) {
          console.error(`HTTP ${res.status} bei der Anfrage an /customers/search`, res)
        }
        return res
      })
  }

  static async searchVehicle(searchObject = {
    "licensePlate": "",
    "vehicleIdentificationNumber": ""
  }) {
    return axios(`${config.URL_BASE}api/v1/customers/search/vehicle`,
      {
        headers: {
          "Authorization": sessionStore.authorization
        },
        method: "POST",
        data: {
          licensePlate: searchObject.licensePlate || null,
          vehicleIdentificationNumber: searchObject.vehicleIdentificationNumber || null
        }
      })
      .then(res => {
        if (res.status !== 200) {
          console.error(`HTTP ${res.status} bei der Anfrage an /customers/search/vehicle`, res)
        }
        return res
      })
  }

}

